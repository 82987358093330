/* eslint-disable react/display-name */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Table,
  Form,
  Input,
  Space,
  Tooltip,
  Popconfirm,
  Modal,
  message,
  Spin,
  Button,
  Image,
  Pagination,
  Card,
  Upload,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  PushpinOutlined,
  PlusOutlined,
  UpCircleOutlined,
  DownCircleOutlined,
} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {storage} from '@/api/storage';
import {
  get,
  post,
  remove,
  update,
} from '@/api/request';
import {
  GETLOCATIONLIST,
  ADDLOCATION,
  UPDATELOCATION,
  DELETELOCATION,
  SETMAINLOCATION,
  SORTLOCATION,
  GETALLIMAGES,
  UPLOAD,
} from '@/api/index';
import './style.css';

const ContactUs = ({history}) => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const [editModal, setEditModal] = useState(false);
  const [httpLoading, setHttpLoading] = useState(false);
  const [userInfo] = useState(storage.getData('userInfo') ?? null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [locationList, setLocationList] = useState([]);
  const [imageUrl, setImageUrl] = useState('');
  const [imagesBoxVisible, setImagesBoxVisible] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [imageListPageConfig, setImageListPageConfig] = useState({
    pageSize: 20,
    total: 0,
  });
  const columns = [
    {
      title: t('location'),
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: t('address'),
      key: 'address',
      render: (record)=>{
        return (
          <pre>{record.address}</pre>
        );
      },
    },
    {
      title: t('mobileNo'),
      dataIndex: 'cellPhone',
      key: 'cellPhone',
    },
    {
      title: t('phoneNo'),
      dataIndex: 'contactDetails',
      key: 'contactDetails',
    },
    {
      title: t('email'),
      dataIndex: 'contactEmail',
      key: 'contactEmail',
    },
    {
      title: t('wechatQrcode'),
      key: 'qrCodeUrl',
      render: (record)=>(
        <Image
          src={record.qrCodeUrl}
          width={100}
        />
      ),
    },
    {
      title: t('status'),
      key: 'status',
      render: (text, record)=>{
        if (record.isMain===1) {
          return (
            <span style={{color: '#f5222d'}}>{t('mainAddress')}</span>
          );
        }
        return (
          <span style={{color: '#52c41a'}}>{t('normalAddress')}</span>
        );
      },
    },
    {
      title: t('action'),
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title={t('edit')} placement="bottom">
            <a style={{fontSize: 20}} onClick={(e)=>{
              e.preventDefault();
              form.setFieldsValue({
                name: record.location,
                address: record.address,
                phoneNo: record.contactDetails??'',
                mobileNo: record.cellPhone??'',
                email: record.contactEmail??'',
                wechatQrcode: record.qrCodeUrl??'',
              });
              setImageUrl(record.qrCodeUrl);
              setCurrentLocation(record);
              setEditModal(true);
            }}><EditOutlined /></a>
          </Tooltip>
          <Tooltip title={t('delete')} placement="bottom">
            <Popconfirm title={t('sureToDelete')} placement="top" onConfirm={()=>{
              handleDeleteLocation(record.id);
            }} okText={t('yes')} cancelText={t('no')}>
              <a style={{fontSize: 20}}><DeleteOutlined /></a>
            </Popconfirm>
          </Tooltip>
          {record.isMain===0?<Tooltip title={t('setMainAddress')} placement="bottom">
            <Popconfirm title={t('sureToSetMainAddress')} placement="top" onConfirm={()=>{
              handleSetMainLocation(record.id);
            }} okText={t('yes')} cancelText={t('no')}>
              <a style={{fontSize: 20}}><PushpinOutlined /></a>
            </Popconfirm>
          </Tooltip>:null}
          {record.sort!==1?<Tooltip title={t('up')} placement="bottom">
            <a style={{fontSize: 20}} onClick={(e)=>{
              e.preventDefault();
              handleSortLocation('up', record);
            }}><UpCircleOutlined /></a>
          </Tooltip>:null}
          {locationList.length!==record.sort-1?<Tooltip title={t('down')} placement="bottom">
            <a style={{fontSize: 20}} onClick={(e)=>{
              e.preventDefault();
              handleSortLocation('down', record);
            }}><DownCircleOutlined /></a>
          </Tooltip>:null}
        </Space>
      ),
    },
  ];
  const props = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    accept: '.png,.jpg,.jpeg',
    customRequest: (info)=>{
      const formData = new FormData();
      formData.append('file', info.file);
      post(UPLOAD, formData, {
        'Content-Type': 'multipart/form-data',
        'token': userInfo.token,
      }).then((res)=>{
        message.success(res.data.msg);
        setImageUrl(res.data.data.original_Image_url);
        form.setFieldsValue({
          wechatQrcode: res.data.data.original_Image_url,
        });
      }).catch((error) => {
        message.error({
          content: error.toString(), key: 'netError', duration: 2,
        });
      }).finally(() => null);
    },
    onChange: (info)=>{
      // handleImgChange(info);
    },
  };
  // const handleImgChange = (info) => {
  //   if (info.file.status === 'uploading') {
  //     return;
  //   }
  //   if (info.file.status === 'done') {
  //     // Get this url from response in real world.
  //     getBase64(info.file.originFileObj, (imageUrl) =>
  //       console.log('done'),
  //     );
  //   }
  // };
  const handleGetLocationList = (set)=>{
    get(GETLOCATIONLIST, {}, userInfo.token).then((res) => {
      set(res.data.data.locationList);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => setHttpLoading(false));
  };
  const handleAddLocation = (values)=>{
    post(ADDLOCATION, {
      location: values.name,
      address: encodeURIComponent(encodeURIComponent(values.address)),
      contactDetails: encodeURIComponent(encodeURIComponent(values.phoneNo))??'',
      cellPhone: encodeURIComponent(encodeURIComponent(values.mobileNo))??'',
      contactEmail: values.email??'',
      qrCodeUrl: values.wechatQrcode??'',
      sort: locationList.length+1,
      isMain: '0',

    }, {
      'token': userInfo.token,
      'Content-Type': 'application/x-www-form-urlencoded',
    }).then((res) => {
      message.success(res.data.msg);
      handleGetLocationList(setLocationList);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => null);
  };
  const handleUpdateLocation = (values, current)=>{
    console.log(values);
    update(UPDATELOCATION, {
      id: current.id,
      location: values.name,
      address: encodeURIComponent(encodeURIComponent(values.address)),
      contactDetails: encodeURIComponent(encodeURIComponent(values.phoneNo))??'',
      cellPhone: encodeURIComponent(encodeURIComponent(values.mobileNo))??'',
      contactEmail: values.email??'',
      qrCodeUrl: values.wechatQrcode??'',
      sort: current.sort,
      isMain: current.isMain,
    }, {
      'token': userInfo.token,
      'Content-Type': 'application/x-www-form-urlencoded',
    }).then((res) => {
      message.success(res.data.msg);
      handleGetLocationList(setLocationList);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => null);
  };
  const handleDeleteLocation = (id)=>{
    remove(`${DELETELOCATION}${id}`, {}, userInfo.token).then((res) => {
      message.success(res.data.msg);
      handleGetLocationList(setLocationList);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => null);
  };
  const handleSetMainLocation = (id)=>{
    update(`${SETMAINLOCATION}${id}`, {}, {
      'token': userInfo.token,
      'Content-Type': 'application/x-www-form-urlencoded',
    }).then((res) => {
      message.success(res.data.msg);
      handleGetLocationList(setLocationList);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => null);
  };
  const handleGetAllImages = (pageNo, pageSize=14, set)=>{
    get(`${GETALLIMAGES}&pageNo=${pageNo}&pageSize=${pageSize}`, {}, userInfo.token).then((res) => {
      set(res.data.data.rows);
      setImageListPageConfig({
        pageSize: pageSize,
        total: res.data.data.total,
      });
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => null);
  };
  const handleSortLocation = (type, current)=>{
    const data = [...locationList];
    const index = data.findIndex((item)=>item.sort===current.sort);
    if (type==='down') {
      [data[index], data[index+1]] = [data[index+1], data[index]];
    } else {
      [data[index-1], data[index]] = [data[index], data[index-1]];
    }
    const info = data.map((item, index)=>{
      return {
        id: item.id,
        sort: index+1,
      };
    });
    post(SORTLOCATION, {
      info,
    }, {
      'token': userInfo.token,
      'Content-Type': 'application/json',
    }).then((res) => {
      message.success(res.data.msg);
      handleGetLocationList(setLocationList);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => null);
  };
  useEffect(() => {
    handleGetLocationList(setLocationList);
  }, []);
  useEffect(() => {
    handleGetAllImages(1, 14, setImageList);
  }, []);
  return (
    <div className="contact-us">
      <Spin spinning={httpLoading}>
        <Row style={{margin: '16px 0'}}>
          <Col span={2}>
            <div className="btn-box">
              <Button
                size="small"
                shape="circle"
                type="primary"
                icon={<PlusOutlined />}
                onClick={()=>{
                  form.resetFields();
                  setEditModal(true);
                }}/>
              {t('add')}
            </div>
          </Col>
        </Row>
        <Row gutter={[8, 24]}>
          <Col span={24}>
            <Table bordered columns={columns} dataSource={locationList}></Table>
          </Col>
        </Row>
      </Spin>
      <Modal
        title={t('edit')}
        visible={editModal}
        onCancel={()=>{
          setEditModal(false);
          setEditModal(false);
          setImagesBoxVisible(false);
          setImageUrl('');
        }}
        onOk={()=>{
          form.submit();
        }}
        destroyOnClose
        width={1200}
      >
        <Form
          form={form}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 18,
          }}
          onFinish={(values)=>{
            // console.log(values);
            if (currentLocation===null) {
              handleAddLocation(values);
            } else {
              handleUpdateLocation(values, currentLocation);
            }
            setHttpLoading(true);
            setCurrentLocation(null);
            setEditModal(false);
            setImagesBoxVisible(false);
            setImageUrl('');
          }}
        >
          <Form.Item
            name="name"
            label={t('location')}
            rules={[{required: true, message: t('required'),
              whitespace: true}]}
            shouldUpdate
          >
            <Input maxLength={20}/>
          </Form.Item>
          <Form.Item
            name="mobileNo"
            label={t('mobileNo')}
            shouldUpdate
          >
            <Input maxLength={20} />
          </Form.Item>
          <Form.Item
            name="phoneNo"
            label={t('phoneNo')}
            shouldUpdate
          >
            <Input maxLength={30} />
          </Form.Item>
          <Form.Item
            name="address"
            label={t('address')}
            rules={[{required: true, message: t('required'),
              whitespace: true}]}
            shouldUpdate
          >
            <Input.TextArea rows={10} style={{resize: 'none'}} maxLength={200}/>
          </Form.Item>
          <Form.Item
            name="email"
            label={t('email')}
            shouldUpdate
          >
            <Input maxLength={30} />
          </Form.Item>
          {/* <Form.Item
            name="wechatQrcode"
            label={t('wechatQrcode')}
            shouldUpdate
          >
            <Input />
          </Form.Item> */}
          <Form.Item style={{height: 104}} label={t('wechatQrcode')}>
            <Space>
              <Upload
                {...props}
                listType="picture-card"
                showUploadList={false}
              >
                {imageUrl?<img
                  src={imageUrl}
                  alt="avatar"
                  style={{maxWidth: 100, maxHeight: 100}} /> : (<div style={{marginTop: 8}}>{t('upload')}</div>)}
              </Upload>
              {!imagesBoxVisible?<Button type="primary" onClick={(e)=>{
                setImagesBoxVisible(true);
              }}>{t('chooseImage')}</Button>:
              <Button type="primary" onClick={()=>{
                setImagesBoxVisible(false);
              }}>{t('close')}</Button>}
              {imageUrl!==''?<Button type="primary" onClick={()=>{
                setImageUrl('');
                form.setFieldsValue({
                  wechatQrcode: '',
                });
              }}>{t('delete')}</Button>:null}
            </Space>
          </Form.Item>
          <Form.Item
            name="wechatQrcode"
            label={t('wechatQrcode')}
            shouldUpdate
            hidden
          >
            <Input disabled/>
          </Form.Item>
          <Row gutter={24}>
            <Col span={24}>
              {imagesBoxVisible?(
                <div className="image-box">
                  <Card title={t('uploadedImage')} style={{width: '83.5%', marginLeft: '16.5%'}}>
                    {imageList.map((item, index)=>(
                      <div key={index} className="image-wapper">
                        <Tooltip title={t('choose')}>
                          <img
                            style={{maxWidth: 100, maxHeight: 100}}
                            src={item.url}
                            onClick={()=>{
                              setImageUrl(item.url);
                              form.setFieldsValue({
                                wechatQrcode: item.url,
                              });
                              setImagesBoxVisible(false);
                            }}
                          />
                        </Tooltip>
                      </div>
                    ))}
                  </Card>
                  <Pagination
                    size="small"
                    showSizeChanger={false}
                    style={{margin: '20px 10px 0 0', float: 'right'}}
                    {...imageListPageConfig}
                    onChange={(page)=>{
                      handleGetAllImages(page, 14, setImageList);
                    }}
                  />
                </div>):null}
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>

  );
};

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

ContactUs.propTypes = {
  history: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(ContactUs));
