import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Upload,
  message, Row, Col,
} from 'antd';
import {} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {storage} from '@/api/storage';
import {get, post} from '@/api/request';
import {
  GETEXTRAINFO,
  UPLOAD,
  SETEXTRAINFO,
} from '@/api/index';
import './style.css';
const layout = {
  labelCol: {span: 4},
  wrapperCol: {span: 12},
};
const tailLayout = {
  wrapperCol: {offset: 4, span: 6},
};
const ExtraConfig = ({history}) => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const [userInfo] = useState(storage.getData('userInfo') ?? null);
  // const [imageUrl] = useState(null);
  const [currentImage, setCurrentImage] = useState('');
  const [extraInfo, setExtraInfo] = useState({});
  const props = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    accept: '.png,.jpg,.jpeg',
    customRequest: (info)=>{
      const formData = new FormData();
      formData.append('file', info.file);
      post(UPLOAD, formData, {
        'Content-Type': 'multipart/form-data',
        'token': userInfo.token,
      }).then((res)=>{
        message.success(res.data.msg);
        const data = {...extraInfo};
        data[currentImage] = res.data.data.thumbnail_url;
        setExtraInfo(data);
        form.setFieldsValue({
          [currentImage]: res.data.data.original_Image_url,
        });
      }).catch((error) => {
        message.error({
          content: error.toString(), key: 'netError', duration: 2,
        });
      }).finally(() => null);
    },
    onChange: (e)=>{
      console.log(e);
    },
  };
  const handleGetExtraInfo = ()=>{
    get(GETEXTRAINFO, {}, userInfo.token).then((res) => {
      setExtraInfo({
        websiteLogo: res.data.data.leftTopImg,
        companyLogo: res.data.data.rightTopImg,
        wechatQrcode: res.data.data.weixinQrCode,
        linkinQrcode: res.data.data.linkedQrCode,
        id: res.data.data.id,
      });
      form.setFieldsValue({
        copyright: res.data.data.copyright,
        clause: res.data.data.clause,
        privacy: res.data.data.privacyPolicy,
        websiteLogoUrl: res.data.data.leftTopImgUrl,
        companyLogoUrl: res.data.data.rightTopImgUrl,
        websiteLogo: res.data.data.leftTopImg,
        companyLogo: res.data.data.rightTopImg,
        wechatQrcode: res.data.data.weixinQrCode,
        linkinQrcode: res.data.data.linkedQrCode,
      });
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => null);
  };
  const handleSetExtraInfo = (values)=>{
    post(SETEXTRAINFO, {
      leftTopImg: values.websiteLogo,
      rightTopImg: values.companyLogo,
      linkedQrCode: values.linkinQrcode,
      weixinQrCode: values.wechatQrcode,
      clause: values.clause,
      privacyPolicy: values.privacy,
      copyright: values.copyright,
      leftTopImgUrl: values.websiteLogoUrl,
      rightTopImgUrl: values.companyLogoUrl,
      id: extraInfo.id,
    }, {
      'Content-Type': 'application/x-www-form-urlencoded',
      'token': userInfo.token,
    }).then((res)=>{
      message.success(res.data.msg);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => null);
  };
  useEffect(() => {
    handleGetExtraInfo();
  }, []);
  return (
    <div className="extra-config">
      <Form
        form={form}
        {...layout}
        onFinish={(values)=>{
          handleSetExtraInfo(values);
        }}
      >
        <Form.Item
          noStyle
          style={{height: 128}}
        >
          <Row>
            <Col span={4} className="text-right logoUrl">
              <b style={{color: 'red'}}>* </b>
              <b style={{color: '#000000'}}> {t('websiteLogo')}：&nbsp;</b>
            </Col>
            <Col span={2}>
              <Form.Item
                name="websiteLogo"
                rules={[{required: true, message: t('required'),
                  whitespace: true}]}
                style={{height: 104}}
              >
                <Upload
                  listType="picture-card"
                  showUploadList={false}
                  {...props}
                  onClick={(e)=>{
                    setCurrentImage('websiteLogo');
                  }}
                >
                  {extraInfo.websiteLogo ? <img src={extraInfo.websiteLogo} alt="avatar" style={{width: '100%'}} />:
                    <div style={{marginTop: 8}}>Upload</div>}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="websiteLogoUrl"
                label={t('relatedUrl')}
                rules={[{required: true, message: t('required'),
                  whitespace: true}]}
                style={{margin: '30px 0 0 ', paddingTop: '-20px'}}
              >
                <Input placeholder={t('relatedUrl')}/>
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          noStyle
          style={{height: 128}}
        >
          <Row>
            <Col span={4} className="text-right logoUrl">
              <b style={{color: 'red'}}>* </b>
              <b style={{color: '#000000'}}> {t('companyLogo')}：&nbsp;</b>
            </Col>
            <Col span={2}>
              <Form.Item
                name="companyLogo"
                rules={[{required: true, message: t('required'),
                  whitespace: true}]}
                style={{height: 104}}
              >
                <Upload
                  listType="picture-card"
                  showUploadList={false}
                  {...props}
                  onClick={(e)=>{
                    setCurrentImage('companyLogo');
                  }}
                >
                  {extraInfo.companyLogo ? <img src={extraInfo.companyLogo} alt="avatar" style={{width: '100%'}} />:
                    <div style={{marginTop: 8}}>Upload</div>}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="companyLogoUrl"
                label={t('relatedUrl')}
                rules={[{required: true, message: t('required'),
                  whitespace: true}]}
                style={{margin: '30px 0 0 ', paddingTop: '-20px'}}
              >
                <Input placeholder={t('relatedUrl')}/>
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          label={t('linkedinQrcode')}
          name="linkinQrcode"
          rules={[{required: true, message: t('required'),
            whitespace: true}]}
          style={{height: 104}}
          className="imgUpload"
        >
          <Upload
            listType="picture-card"
            showUploadList={false}
            {...props}
            onClick={(e)=>{
              setCurrentImage('linkinQrcode');
            }}
          >
            {extraInfo.linkinQrcode ? <img src={extraInfo.linkinQrcode} alt="avatar" style={{width: '100%'}} />:
              <div style={{marginTop: 8}}>Upload</div>}
          </Upload>
        </Form.Item>
        <Form.Item
          label={t('wechatQrcode')}
          rules={[{required: true, message: t('required'),
            whitespace: true}]}
          name="wechatQrcode"
          style={{height: 104}}
          className="imgUpload"
        >
          <Upload
            listType="picture-card"
            showUploadList={false}
            {...props}
            onClick={(e)=>{
              setCurrentImage('wechatQrcode');
            }}
          >
            {extraInfo.wechatQrcode ? <img src={extraInfo.wechatQrcode} alt="avatar" style={{width: '100%'}} />:
            <div style={{marginTop: 8}}>Upload</div>}
          </Upload>
        </Form.Item>

        <Form.Item
          label={t('copyright')}
          name="copyright"
          shouldUpdat
          rules={[{required: true, message: t('required'),
            whitespace: true}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('clause')}
          name="clause"
          shouldUpdate
          rules={[{required: true, message: t('required'),
            whitespace: true}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('privacy')}
          name="privacy"
          shouldUpdate
          rules={[{required: true, message: t('required'),
            whitespace: true}]}
        >
          <Input />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            {t('submit')}
          </Button>
        </Form.Item>
      </Form>
    </div>

  );
};

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

ExtraConfig.propTypes = {
  history: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(ExtraConfig));
