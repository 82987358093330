import React, {useState} from 'react';
import {storage} from '@/api/storage';

const UserAvatar=()=>{
  const [userInfo] = useState(storage.getData('userInfo') ?? null);
  return (<span
    style={{float: 'right', fontSize: 22, marginLeft: 16}}
  >
    {userInfo.username}
  </span>);
};

UserAvatar.propTypes = {};

export default UserAvatar;
