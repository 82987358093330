import React from 'react';
import './style.css';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Card, Tabs} from 'antd';
import UploadPicture from './Components/UploadPicture';
import UploadVideo from './Components/UploadVideo';
import UploadFiles from './Components/UploadFiles';
const {TabPane} = Tabs;

const Multimedia = () => {
  const {t} = useTranslation();
  const callback = (key) => {
    console.log(key);
  };

  return (
    <div className="multimedia" style={{overflowY: 'auto ', height: 'calc(100vh -90px )'}}>
      <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane tab={t('pictureManager')} key="1">

          <UploadPicture/>

        </TabPane>
        <TabPane tab={t('videoManager')} key="2">
          <Card >
            <UploadVideo/>
          </Card>
        </TabPane>
        <TabPane tab={t('fileManager')} key="3">
          <Card >
            <UploadFiles/>
          </Card>
        </TabPane>
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

Multimedia.propTypes = {
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Multimedia);
