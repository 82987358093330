export const modelForm = [
  {
    id: 1, data: [],
  },
  {
    id: 2, data: [
      {
        name: 'hcy_1_title',
        label: '标题',
        type: 'title',
      },
    ],
  },
  {
    id: 3, data: [
      {
        name: 'hcy_1_title',
        label: '标题',
        type: 'title',
      },
      {
        name: 'hcy_1_text',
        label: '副标题',
        type: 'text',
      }],
  },
  {
    id: 4, data: [{
      name: 'hcy_1_title',
      label: '标题',
      type: 'title',
    },
    {
      name: 'hcy_1_text',
      label: '副标题',
      type: 'text',
    }],
  },
  {
    id: 5, data: [
      {
        name: 'hcy_1_title',
        label: '标题',
        type: 'title',
      },
      {
        name: 'hcy_1_text',
        label: '正文',
        type: 'text',
      }],
  },
  {
    id: 6, data: [
      {
        name: 'hcy_1_img',
        label: '图片',
        type: 'image',
      },
      {
        name: 'hcy_1_title',
        label: '标题',
        type: 'title',
      },
      {
        name: 'hcy_1_href',
        label: '链接',
        type: 'href',
      },
      {
        name: 'hcy_1_text',
        label: '描述',
        type: 'text',
      }],
  },
  {
    id: 7, data: [
      {
        name: 'hcy_1_title',
        label: '标题',
        type: 'title',
      },
      {
        name: 'hcy_1_href',
        label: '标题链接',
        type: 'href',
      },
      {
        name: 'hcy_1_img',
        label: '图片',
        type: 'image',
      },
      {
        name: 'hcy_2_href',
        label: '图片链接',
        type: 'href',
      },
      {
        name: 'hcy_1_text',
        label: '描述',
        type: 'text',
      },
    ],
  },
  {
    id: 8, data: [
      {
        name: 'hcy_1_img',
        label: '图片',
        type: 'image',
      }],
  },
  {
    id: 9, data: [
      {
        name: 'hcy_1_img',
        label: '图片',
        type: 'image',
      },
    ],
  },
  {
    id: 10, data: [
      {
        name: 'hcy_1_img',
        label: '图片',
        type: 'image',
      },
      {
        name: 'hcy_1_herf',
        label: '图片链接',
        type: 'herf',
      },
      {
        name: 'hcy_1_title',
        label: '图片标题',
        type: 'title',
      },
      {
        name: 'hcy_2_title',
        label: '标题',
        type: 'title',
      },
      {
        name: 'hcy_1_text',
        label: '正文',
        type: 'text',
      },
    ],
  },
  {
    id: 11, data: [{
      name: 'hcy_1_title',
      label: '标题',
      type: 'title',
    },
    {
      name: 'hcy_1_img',
      label: '图片',
      type: 'image',
    },
    {
      name: 'hcy_1_herf',
      label: '链接',
      type: 'herf',
    }],
  },
  {
    id: 12, data: [{
      name: 'hcy_1_title',
      label: '标题',
      type: 'title',
    },
    {
      name: 'hcy_1_text',
      label: '正文',
      type: 'text',
    }],
  },
  {
    id: 13, data: [
      {
        name: 'hcy_1_img',
        label: '图片',
        type: 'image',
      },
      {
        name: 'hcy_1_title',
        label: '标题',
        type: 'title',
      },
      {
        name: 'hcy_1_text',
        label: '描述',
        type: 'text',
      },
      {
        name: 'hcy_1_href',
        label: '链接',
        type: 'href',
      }],
  },
  {
    id: 14, data: [
      {
        name: 'hcy_1_img',
        label: '图片',
        type: 'image',
      },
      {
        name: 'hcy_1_title',
        label: '标题',
        type: 'title',
      },
      {
        name: 'hcy_1_text',
        label: '正文',
        type: 'text',
      },
    ],
  },
  {
    id: 15, data: [{
      name: 'hcy_1_img',
      label: '图片1',
      type: 'image',
    },
    {
      name: 'hcy_1_title',
      label: '标题1',
      type: 'title',
    },
    {
      name: 'hcy_1_text',
      label: '正文1',
      type: 'text',
    },
    {
      name: 'hcy_1_href',
      label: '链接1',
      type: 'href',
    },
    {
      name: 'hcy_2_img',
      label: '图片2',
      type: 'image',
    },
    {
      name: 'hcy_2_title',
      label: '标题2',
      type: 'title',
    },
    {
      name: 'hcy_2_text',
      label: '正文2',
      type: 'text',
    },
    {
      name: 'hcy_2_href',
      label: '链接2',
      type: 'href',
    }],
  },
  {
    id: 16, data: [
      {
        name: 'hcy_1_title',
        label: '主标题',
        type: 'title',
      },
      {
        name: 'hcy_1_img',
        label: '图片1',
        type: 'image',
      },
      {
        name: 'hcy_2_title',
        label: '标题1',
        type: 'title',
      },
      {
        name: 'hcy_1_text',
        label: '正文1',
        type: 'text',
      },
      {
        name: 'hcy_2_img',
        label: '图片2',
        type: 'image',
      },
      {
        name: 'hcy_3_title',
        label: '标题2',
        type: 'title',
      },
      {
        name: 'hcy_2_text',
        label: '正文2',
        type: 'text',
      },
    ],
  },
  {
    id: 17, data: [
      {
        name: 'hcy_1_title',
        label: '标题',
        type: 'title',
      },
      {
        name: 'hcy_1_text',
        label: '正文第一段',
        type: 'text',
      },
      {
        name: 'hcy_2_text',
        label: '正文第二段',
        type: 'text',
      },
      {
        name: 'hcy_3_text',
        label: '正文第三段',
        type: 'text',
      },
      {
        name: 'hcy_1_herf',
        label: '菜单1',
        type: 'text',
      },
      {
        name: 'hcy_2_herf',
        label: '菜单1.1',
        type: 'herf',
      },
      {
        name: 'hcy_3_herf',
        label: '菜单1.2',
        type: 'herf',
      },
      {
        name: 'hcy_4_herf',
        label: '菜单1.3',
        type: 'herf',
      },
      {
        name: 'hcy_5_herf',
        label: '菜单1.4',
        type: 'herf',
      },
      {
        name: 'hcy_6_herf',
        label: '菜单2',
        type: 'herf',
      },
      {
        name: 'hcy_7_herf',
        label: '菜单2.1',
        type: 'herf',
      },
      {
        name: 'hcy_8_herf',
        label: '菜单2.2',
        type: 'herf',
      },
      {
        name: 'hcy_9_herf',
        label: '菜单3',
        type: 'herf',
      },
      {
        name: 'hcy_10_herf',
        label: '菜单3.1',
        type: 'herf',
      },
      {
        name: 'hcy_11_herf',
        label: '菜单3.2',
        type: 'herf',
      },
      {
        name: 'hcy_12_herf',
        label: '菜单3.3',
        type: 'herf',
      },
    ],
  },
  {
    id: 18, data: [
      {
        name: 'hcy_1_title',
        label: '主标题',
        type: 'title',
      },
      {
        name: 'hcy_1_img',
        label: '图片1',
        type: 'image',
      },
      {
        name: 'hcy_2_title',
        label: '标题1',
        type: 'title',
      },
      {
        name: 'hcy_2_img',
        label: '图片2',
        type: 'image',
      },
      {
        name: 'hcy_3_title',
        label: '标题2',
        type: 'title',
      },
      {
        name: 'hcy_3_img',
        label: '图片3',
        type: 'image',
      },
      {
        name: 'hcy_4_title',
        label: '标题3',
        type: 'title',
      },
      {
        name: 'hcy_4_img',
        label: '图片4',
        type: 'image',
      },
      {
        name: 'hcy_5_title',
        label: '标题4',
        type: 'title',
      },
      {
        name: 'hcy_5_img',
        label: '图片5',
        type: 'image',
      },
      {
        name: 'hcy_6_title',
        label: '标题5',
        type: 'title',
      },
      {
        name: 'hcy_6_img',
        label: '图片6',
        type: 'image',
      },
      {
        name: 'hcy_7_title',
        label: '标题6',
        type: 'title',
      },
      {
        name: 'hcy_1_herf',
        label: '链接及链接文案',
        type: 'herf',
      },
    ],
  },
  {
    id: 19, data: [
      {
        name: 'hcy_1_title',
        label: '主标题',
        type: 'title',
      },
      {
        name: 'hcy_1_img',
        label: '图片1',
        type: 'image',
      },
      {
        name: 'hcy_2_title',
        label: '标题1',
        type: 'title',
      },
      {
        name: 'hcy_2_img',
        label: '图片2',
        type: 'image',
      },
      {
        name: 'hcy_3_title',
        label: '标题2',
        type: 'title',
      },
      {
        name: 'hcy_3_img',
        label: '图片3',
        type: 'image',
      },
      {
        name: 'hcy_4_title',
        label: '标题3',
        type: 'title',
      },
      {
        name: 'hcy_4_img',
        label: '图片4',
        type: 'image',
      },
      {
        name: 'hcy_5_title',
        label: '标题4',
        type: 'title',
      },
      {
        name: 'hcy_5_img',
        label: '图片5',
        type: 'image',
      },
      {
        name: 'hcy_6_title',
        label: '标题5',
        type: 'title',
      },
      {
        name: 'hcy_6_img',
        label: '图片6',
        type: 'image',
      },
      {
        name: 'hcy_7_title',
        label: '标题6',
        type: 'title',
      },
    ],
  },
  {
    id: 20, data: [
      {
        name: 'hcy_1_title',
        label: '主标题',
        type: 'title',
      },
      {
        name: 'hcy_2_title',
        label: '标题1',
        type: 'title',
      },
      {
        name: 'hcy_3_title',
        label: '标题2',
        type: 'title',
      },
      {
        name: 'hcy_4_title',
        label: '标题3',
        type: 'title',
      },
      {
        name: 'hcy_5_title',
        label: '标题4',
        type: 'title',
      },
      {
        name: 'hcy_6_title',
        label: '标题5',
        type: 'title',
      },
      {
        name: 'hcy_7_title',
        label: '标题6',
        type: 'title',
      },
      {
        name: 'hcy_8_title',
        label: '标题1.1',
        type: 'title',
      },
      {
        name: 'hcy_1_text',
        label: '正文1',
        type: 'text',
      },
      {
        name: 'hcy_9_title',
        label: '标题2.1',
        type: 'title',
      },
      {
        name: 'hcy_2_text',
        label: '正文2',
        type: 'text',
      },
      {
        name: 'hcy_10_title',
        label: '标题3.1',
        type: 'title',
      },
      {
        name: 'hcy_3_text',
        label: '正文3',
        type: 'text',
      },
      {
        name: 'hcy_11_title',
        label: '标题4.1',
        type: 'title',
      },
      {
        name: 'hcy_4_text',
        label: '正文4',
        type: 'text',
      },
      {
        name: 'hcy_12_title',
        label: '标题5.1',
        type: 'title',
      },
      {
        name: 'hcy_5_text',
        label: '正文5',
        type: 'text',
      },
      {
        name: 'hcy_13_title',
        label: '标题6.1',
        type: 'title',
      },
      {
        name: 'hcy_6_text',
        label: '正文6',
        type: 'text',
      }],
  },
  {
    id: 21, data: [
      {
        name: 'hcy_1_title',
        label: '主标题',
        type: 'title',
      },
      {
        name: 'hcy_1_text',
        label: '标题1',
        type: 'text',
      },
      {
        name: 'hcy_2_title',
        label: '标题1.1',
        type: 'title',
      },
      {
        name: 'hcy_4_text',
        label: '正文1',
        type: 'text',
      },
      {
        name: 'hcy_2_text',
        label: '标题2',
        type: 'text',
      },
      {
        name: 'hcy_3_title',
        label: '标题2.1',
        type: 'title',
      },
      {
        name: 'hcy_5_text',
        label: '正文2',
        type: 'text',
      },
      {
        name: 'hcy_3_text',
        label: '标题3',
        type: 'text',
      },
      {
        name: 'hcy_4_title',
        label: '标题3.1',
        type: 'title',
      },
      {
        name: 'hcy_6_text',
        label: '正文3',
        type: 'text',
      },
    ],
  },
  {
    id: 22, data: [
      {
        name: 'hcy_1_title',
        label: '主标题',
        type: 'title',
      },
      {
        name: 'hcy_1_img',
        label: '图片1',
        type: 'image',
      },
      {
        name: 'hcy_2_title',
        label: '标题1',
        type: 'title',
      },
      {
        name: 'hcy_2_img',
        label: '图片2',
        type: 'image',
      },
      {
        name: 'hcy_3_title',
        label: '标题2',
        type: 'title',
      },
      {
        name: 'hcy_3_img',
        label: '图片3',
        type: 'image',
      },
      {
        name: 'hcy_4_title',
        label: '标题3',
        type: 'title',
      },
    ],
  },
  {
    id: 23, data: [
      {
        name: 'hcy_1_title',
        label: '主标题',
        type: 'title',
      },
      {
        name: 'hcy_1_img',
        label: '图片1',
        type: 'image',
      },
      {
        name: 'hcy_2_title',
        label: '标题1',
        type: 'title',
      },
      {
        name: 'hcy_1_text',
        label: '正文1',
        type: 'text',
      },
      {
        name: 'hcy_2_img',
        label: '图片2',
        type: 'image',
      },
      {
        name: 'hcy_3_title',
        label: '标题2',
        type: 'title',
      },
      {
        name: 'hcy_2_text',
        label: '正文2',
        type: 'text',
      },
      {
        name: 'hcy_3_img',
        label: '图片3',
        type: 'image',
      },
      {
        name: 'hcy_4_title',
        label: '标题3',
        type: 'title',
      },
      {
        name: 'hcy_3_text',
        label: '正文3',
        type: 'text',
      },
      {
        name: 'hcy_4_img',
        label: '图片4',
        type: 'image',
      },
      {
        name: 'hcy_5_title',
        label: '标题4',
        type: 'title',
      },
      {
        name: 'hcy_4_text',
        label: '正文4',
        type: 'text',
      }],
  },
  {
    id: 24, data: [
      {
        name: 'hcy_1_title',
        label: '主标题',
        type: 'title',
      },
      {
        name: 'hcy_1_img',
        label: '图片1',
        type: 'image',
      },
      {
        name: 'hcy_2_title',
        label: '标题1',
        type: 'title',
      },
      {
        name: 'hcy_1_text',
        label: '正文1',
        type: 'text',
      },
      {
        name: 'hcy_2_img',
        label: '图片2',
        type: 'image',
      },
      {
        name: 'hcy_3_title',
        label: '标题2',
        type: 'title',
      },
      {
        name: 'hcy_2_text',
        label: '正文2',
        type: 'text',
      },
      {
        name: 'hcy_3_img',
        label: '图片3',
        type: 'image',
      },
      {
        name: 'hcy_4_title',
        label: '标题3',
        type: 'title',
      },
      {
        name: 'hcy_3_text',
        label: '正文3',
        type: 'text',
      },
      {
        name: 'hcy_4_img',
        label: '图片4',
        type: 'image',
      },
      {
        name: 'hcy_5_title',
        label: '标题4',
        type: 'title',
      },
      {
        name: 'hcy_4_text',
        label: '正文4',
        type: 'text',
      }],
  },
  {
    id: 25, data: [
      {
        name: 'hcy_1_img',
        label: '图片1',
        type: 'image',
      },
      {
        name: 'hcy_1_title',
        label: '标题1',
        type: 'title',
      },
      {
        name: 'hcy_1_text',
        label: '副标题1',
        type: 'text',
      },
      {
        name: 'hcy_1_href',
        label: '链接1',
        type: 'href',
      },
      {
        name: 'hcy_2_img',
        label: '图片2',
        type: 'image',
      },
      {
        name: 'hcy_2_title',
        label: '标题2',
        type: 'title',
      },
      {
        name: 'hcy_2_text',
        label: '副标题2',
        type: 'text',
      },
      {
        name: 'hcy_2_href',
        label: '链接2',
        type: 'href',
      },
    ],
  }, {
    id: 26, data: [
      {
        name: 'hcy_1_title',
        label: '标题',
        type: 'title',
      },
      {
        name: 'hcy_1_herf',
        label: '链接及链接文案',
        type: 'herf',
      }],
  },
  {
    id: 27, data: [
      {
        name: 'hcy_1_video',
        label: '视频',
        type: 'video',
      },
    ],
  },
  {
    id: 28, data: [
      {
        name: 'hcy_1_title',
        label: '标题1',
        type: 'title',
      },
      {
        name: 'hcy_1_text',
        label: '正文1',
        type: 'text',
      },
      {
        name: 'hcy_2_title',
        label: '标题2',
        type: 'title',
      },
      {
        name: 'hcy_2_text',
        label: '正文2',
        type: 'text',
      }],
  },
  {
    id: 30, data: [
      {
        name: 'hcy_1_title',
        label: '标题',
        type: 'title',
      },
      {
        name: 'hcy_1_text',
        label: '正文第一段',
        type: 'text',
      },
      {
        name: 'hcy_2_text',
        label: '正文第二段',
        type: 'text',
      },
      {
        name: 'hcy_3_text',
        label: '正文第三段',
        type: 'text',
      },
    ],
  },
  {
    id: 31, data: [
      {
        name: 'hcy_1_title',
        label: '标题',
        type: 'title',
      },
      {
        name: 'hcy_1_href',
        label: '链接',
        type: 'href',
      },
      {
        name: 'hcy_1_text',
        label: '描述',
        type: 'text',
      },
    ],
  },
];
