import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import './style.css';

const Template = ({el}) => {
  return (
    <div className="menu" dangerouslySetInnerHTML={{__html: el}}>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

Template.propTypes = {
  el: PropTypes.string.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Template);
