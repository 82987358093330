import React, {useEffect, useRef, useState} from 'react';
import {BrowserRouter, Link, Route, Switch, withRouter} from 'react-router-dom';
import './App.less';
import {
  Button,
  Form,
  Input,
  Layout,
  message,
  Modal,
  // Radio,
} from 'antd';
import {useTranslation} from 'react-i18next';
import {storage} from '@/api/storage';
import './locales/i18n';
import Menus from '@/components/menus';
import Home from '@/pages/home';
import Login from '@/pages/login';
import BannerConfig from '@/pages/bannerConfig';
import Article from '@/pages/article';
import MultiText from '@/pages/multiText';
import MenuConfig from '@/pages/menuConfig';
import ExtraConfig from '@/pages/extraConfig';
import Multimedia from '@/pages/multimedia';
import ContactUs from '@/pages/contactUs';
import {LOGINOUT, RESETPASSWORD} from './api/index';
import Logo from '@/assets/ACCLIVIS.png';
import {post} from './api/request';
import {LockOutlined, PoweroffOutlined} from '@ant-design/icons';
import UserAvatar from './components/UserAvatar';

const App = () => {
  const {t} = useTranslation();
  const {Header, Content, Sider} = Layout;
  const [userInfo, setUserInfo] = useState(storage.getData('userInfo') ?? null);
  const [logged, setLogged] = useState(false);
  const [resetPWD, setRestPWD] = useState(false);
  const linkRef = useRef();
  // const {i18n} = useTranslation();
  const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 16},
  };
  const tailLayout = {
    wrapperCol: {offset: 0, span: 24},
  };
  const onLogout =()=>{
    post(LOGINOUT, '', {
      'token': userInfo.token,
    }).then((res)=>{
      if (res.data.code===200) {
        storage.clearData('userInfo');
        setLogged(false);
        message.success(t('loginOutSuccess'));
        window.location.href='/login';
      }
    }).catch((error)=>{
      Modal.error({
        content: error.message.toString(),
      });
    });
  };
  const resetPassword=(value)=>{
    const formData = new FormData();
    formData.append('oldPassword', value.oldPassword);
    formData.append('newPassword', value.newPassword);
    post(RESETPASSWORD, formData, {
      'token': storage.getData('userInfo').token,
    }).then((res)=>{
      if (res.data.code===200) {
        setRestPWD(false);
      }
    }).catch((error)=>{
      Modal.error({
        content: error.message.toString(),
      });
    });
  };
  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(userInfo, 'token') &&
    userInfo.token !== '') {
      setLogged(true);
      setUserInfo(userInfo);
    } else {
      setLogged(false);
      linkRef.current.click();
    }
    return () => {
    };
  }, []);
  return (
    <BrowserRouter basename="/">
      <div className="App">
        <Link to="/login" ref={linkRef}></Link>
        {!logged?(<Switch>
          <Route exact path="/login" render={()=>(
            <Login login={()=>setLogged(true)}/>
          )}/>
        </Switch>):
        (<Layout style={{height: '100%', overflow: 'hidden'}}>
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
            }}
            onCollapse={(collapsed, type) => {
            }}
          >
            <div className="logo">
              <img src={Logo} alt="logo" width="104"/>
            </div>
            <Menus />
          </Sider>
          <Layout>
            <Header className="site-layout-sub-header-background" style={{paddingRight: 16}}>
              {/* <Radio.Group
                onChange={(e) => {
                  i18n.changeLanguage(e.target.value === 'Chinese' ? 'zh' : 'en');
                }} defaultValue="Chinese">
                <Radio value='English'>English</Radio>
                <Radio value='Chinese'>Chinese</Radio>
              </Radio.Group> */}
              <Button
                onClick={onLogout}
                type="text"
                style={{float: 'right', marginTop: 16, marginLeft: 8}}>
                <PoweroffOutlined style={{fontSize: 22}}/>
              </Button>
              <Button
                onClick={() => setRestPWD(true)}
                style={{float: 'right', marginTop: 16, marginLeft: 8}}
                type="text"
              >
                <LockOutlined style={{fontSize: 22}}/>
              </Button>
              <UserAvatar/>
              <Modal title={t('resetPassword')} visible={resetPWD} footer={false} onCancel={() => setRestPWD(false)}>
                <Form
                  {...layout}
                  name="basic"
                  initialValues={{remember: true}}
                  onFinish={resetPassword}
                >
                  <Form.Item
                    label={t('oldPassword')}
                    name="oldPassword"
                    rules={[{required: true, message: t('oldPasswordInfo')}]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    label={t('newPassword')}
                    name="newPassword"
                    rules={[{required: true, message: t('newPasswordInfo')}]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item {...tailLayout} className="textCenter">
                    <Button type="primary" htmlType="submit">
                      {t('submit')}
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
            </Header>
            <Content style={{margin: '24px 16px 0', overflowY: 'auto'}}>
              <div
                className="site-layout-background"
                style={{minHeight: 'calc(100vh - 89px)'}}>
                <Switch>
                  <Route
                    path="/home" component={withRouter(Home)} />
                  <Route
                    path="/bannerConfig" component={withRouter(BannerConfig)} />
                  <Route
                    path="/article"
                    component={withRouter(Article)} />
                  <Route
                    path="/multiText"
                    component={withRouter(MultiText)} />
                  <Route
                    path="/multimedia"
                    component={withRouter(Multimedia)} />
                  <Route
                    path="/menuConfig"
                    component={withRouter(MenuConfig)} />
                  <Route
                    path="/extraConfig"
                    component={withRouter(ExtraConfig)} />
                  <Route
                    path="/contactUs"
                    component={withRouter(ContactUs)} />
                </Switch>
              </div>
            </Content>
            {/*
            <Footer style={{textAlign: 'center'}}>一个可爱的页脚@HYCTech</Footer>
            */}
          </Layout>
        </Layout>)}
      </div>
    </BrowserRouter>
  );
};

export default App;
