import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {storage} from '@/api/storage';
import './style.css';

const Home = ({history}) => {
  const [userInfo] = useState(storage.getData('userInfo') ?? null);
  useEffect(() => {
    if (Object.hasOwnProperty.call(userInfo, 'token') &&
    userInfo.token !== '') {
      return false;
    } else {
      history.push('login');
    }
  }, []);
  return (
    <div className="home">
      <div className="textRight bg-Wight">版本号：V1.0.0</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

Home.propTypes = {
  history: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Home));
