import React, {useState, useEffect, useCallback} from 'react';
import './style.css';
import {connect} from 'react-redux';
import {Row, Col, Tree, Space, Button, Modal, Select,
  Form, Input, Popconfirm, message, Spin, Divider} from 'antd';
import {PlusOutlined, EyeOutlined, CloseOutlined, EditOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
// import PropTypes from 'prop-types';
import {storage} from '@/api/storage';
import {get, post} from '@/api/request';
import {GETNAVIGATION,
  ADDNAVIGATION,
  UPDATENAVIGATION,
  DELETENAVIGATION,
  GETARTICLELIST,
  RELATEARTICLE,
  PREVIEWPAGE,
} from '@/api/index';

const layout = {
  labelCol: {span: 4},
  wrapperCol: {span: 16},
};
const tailLayout = {
  wrapperCol: {offset: 4, span: 16},
};
const MenuConfig = () => {
  const {t} = useTranslation();
  const [form]=Form.useForm();
  const [userInfo] = useState(storage.getData('userInfo') ?? null);
  const [httpLoading, setHttpLoading] = useState(true);
  const [headerData, setHeaderData] = useState([]);
  const [footerData, setFooterData] = useState([]);
  const [sideData, setSideData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentTreeItem, setCurrentTreeItem] = useState({});
  const [articleList, setArticleList] = useState([]);
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  const [htmlData, setHtmlData] = useState('');
  // const addTreeItem = (type, key, name, orderNum)=> {
  //   return {
  //     title: `${orderNum}. ${name}`,
  //     key: key,
  //     children: [],
  //     attributes: {
  //       type: type,
  //       order_num: orderNum,
  //     },
  //   };
  // };
  const onSelect = (node) => {
    setModalVisible(true);
    setCurrentTreeItem(node);
    form.setFieldsValue({
      title: node.title.split('.')[1].trim(),
      relateArticle: node.attributes.related_files,
    });
  };
  // const onCheck = (checkedKeys, info) => {
  //   console.log('onCheck', checkedKeys, info);
  // };
  const handleAdd = (data, addType, dataType, current)=>{
    const _treeData = [...data];
    setHttpLoading(true);
    switch (addType) {
      case 1:
        {
          // const parent = [
          //   currentTreeItem.key.split('-')[0],
          //   currentTreeItem.key.split('-')[1]].join('-');
          // const a = _treeData.filter((item)=>item.key===parent);
          // const idx = _treeData.findIndex((item)=>item.key===parent);
          // if (a[0].children.length===0) {
          //   a[0].children.push(addTreeItem(dataType, `${parent}-0`, t('secondLevel'), 1));
          // } else {
          //   const c = `${parent}-${a[0].children.length}`;
          //   a[0].children.push(
          //       addTreeItem(dataType, c, t('secondLevel'), a[0].children.length+1));
          //   _treeData[idx].children = a[0].children;
          // }
          addNewMenu(dataType, addType, t('secondLevel'), current);
        }
        break;
      case 2:
        {
          // const p = [
          //   currentTreeItem.key.split('-')[0],
          //   currentTreeItem.key.split('-')[1]].join('-');
          // const pp = [
          //   currentTreeItem.key.split('-')[0],
          //   currentTreeItem.key.split('-')[1],
          //   currentTreeItem.key.split('-')[2]].join('-');
          // const b = _treeData.filter((item)=>item.key===p);
          // const i = _treeData.findIndex((item)=>item.key===p);
          // const d = b[0].children.filter((item)=>item.key===pp);
          // const ii = b[0].children.findIndex((item)=>item.key===pp);
          // if (d[0].children.length===0) {
          //   d[0].children.push(addTreeItem(dataType, `${pp}-0`, t('thirdLevel'), 1));
          // } else {
          //   const c = `${pp}-${d[0].children.length}`;
          //   d[0].children.push(
          //       addTreeItem(dataType, c, t('thirdLevel'), d[0].children.length+1));
          //   _treeData[i].children[ii].children = d[0].children;
          // }
          addNewMenu(dataType, addType, t('thirdLevel'), current);
        }

        break;
      default:
        {
          // const keyArr = data[_treeData.length-1].key.split('-');
          // const key = [keyArr[0], (+keyArr[1])+keyArr.length-1].join('-');
          // _treeData.push(addTreeItem(dataType, key, t('firstLevel'), _treeData.length+1));
          // set(_treeData);
          addNewMenu(dataType, addType, t('firstLevel'), {
            attributes: {
              order_num: _treeData.length+1,
            },
          });
        }
        break;
    }
    setModalVisible(false);
  };
  const handleUpdate = (values)=>{
    setModalVisible(false);
    setHttpLoading(true);
    updateMenu(values, currentTreeItem);
  };
  const handleDelete = (node)=>{
    setModalVisible(false);
    setHttpLoading(true);
    deleteMenu(node);
    // console.log(currentTreeItem);
  };
  const formatTree = (arr)=>{
    const d = [...arr];
    const fn = (data, key)=>{
      for (let i = 0; i < data.length; i++) {
        data[i].key =`${key}-${i}`;
        data[i].title = `${data[i].attributes.order_num}. ${data[i].text}`;
        if (data[i].children.length===0) continue;
        else fn(data[i].children, data[i].key);
      }
    };
    fn(d, '0');
    return d;
  };
  const getArticleList = (type, set)=>{
    get(`${GETARTICLELIST}${type}`, {}, userInfo.token).then((res) => {
      const data = res.data.data.map((item)=>{
        return {
          label: item.title??'Null',
          value: item.id,
        };
      });
      set(data);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => null);
  };
  const getNavigation = useCallback(
      () => {
        get(GETNAVIGATION, {}, userInfo.token).then((res) => {
          setHeaderData(formatTree(res.data.data.headerList[0].children));
          setFooterData(formatTree(res.data.data.footerList[0].children));
          setSideData(formatTree(res.data.data.sideList[0].children));
        }).catch((error) => {
          message.error({
            content: error.toString(), key: 'netError', duration: 2,
          });
        }).finally(() => {
          setHttpLoading(false);
        });
      },
      [userInfo.token],
  );
  const addNewMenu = (datatype, addType, name, current)=>{
    const data = {
      name,
      parentId: 0,
      relateFiles: '',
      orderNum: current.attributes.order_num,
      type: datatype,
    };
    switch (addType) {
      case 1:
        data.parentId = current.id;
        data.orderNum = current.children.length+1;
        break;
      case 2:
        data.parentId = current.id;
        data.orderNum = current.children.length+1;
        break;
      default:
        break;
    }
    post(ADDNAVIGATION, data,
        {
          'token': userInfo.token,
          'Content-Type': 'application/x-www-form-urlencoded',
        }).then((res) => {
      message.success(res.data.msg);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => {
      getNavigation();
    });
  };
  const updateMenu = (values, data)=>{
    post(UPDATENAVIGATION, {
      name: values.title,
      parentId: data.parentId,
      orderNum: data.attributes.order_num,
      type: data.attributes.type,
      menuId: data.id,
    }, {
      'token': userInfo.token,
      'Content-Type': 'application/x-www-form-urlencoded',
    }).then((res) => {
      message.success(res.data.msg);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => {
      getNavigation();
    });
  };
  const deleteMenu = (data)=>{
    post(`${DELETENAVIGATION}${data.id}`, {}, {
      'token': userInfo.token,
      'Content-Type': 'application/x-www-form-urlencoded',
    }).then((res) => {
      message.success(res.data.msg);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => {
      getNavigation();
    });
  };
  const handleRelateArticle = (id, articleId)=>{
    get(articleId===''?RELATEARTICLE+id:RELATEARTICLE+id+'?relatedId='+articleId, {}, userInfo.token).then((res) => {
      message.success(res.data.msg);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => {
      getNavigation();
    });
  };
  const handlePreviewArticle = (id)=>{
    get(`${PREVIEWPAGE}${id}`, {}, userInfo.token).then((res) => {
      setHtmlData(res.data.data);
      setPreviewModalVisible(true);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => null);
  };
  useEffect(() => {
    getNavigation();
  }, [getNavigation]);
  useEffect(() => {
    getArticleList(1, setArticleList);
  }, []);
  return (
    <div className="menu-config">
      <Spin spinning={httpLoading}>
        <Row justify="end">
          <Col span={8} className="menu-list">
            <div style={{fontSize: 20, fontWeight: 'bold'}}>{t('headerMenu')}</div>
            <Divider/>
            <Space>
              <Button
                onClick={()=>handleAdd(headerData, 0, 1)}
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
                size="small"
              />
              <span>{t('addFirstLevel')}</span>
            </Space>
            <Tree
              checkable={false}
              defaultExpandAll
              // onSelect={onSelect}
              treeData={headerData}
              titleRender={(node)=>{
                return (
                  <div className="tree-item-box">
                    <span>{node.title}</span>
                    <div className="btn-group">
                      <a onClick={(e)=>{
                        e.preventDefault();
                        if (!node.attributes.related_files) {
                          message.error('没有关联页面，无法预览');
                          return false;
                        }
                        handlePreviewArticle(node.attributes.related_files);
                      }}><EyeOutlined /></a>
                      <a onClick={(e)=>{
                        e.preventDefault();
                        onSelect(node);
                        // console.log(e);
                      }}><EditOutlined /></a>
                      <Popconfirm
                        placement="top"
                        title={t('sureToDelete')}
                        onConfirm={()=>{
                          handleDelete(node);
                        }}
                        okText={t('yes')}
                        cancelText={t('no')}
                      >
                        <a><CloseOutlined /></a>
                      </Popconfirm>
                      {node.key.split('-').length<3?
                    <a onClick={()=>{
                      if (node.key.split('-').length===2) {
                        handleAdd(headerData, 1, 1, node);
                      } else {
                        handleAdd(headerData, 2, 1, node);
                      }
                    }}><PlusOutlined /></a>:null}
                    </div>
                  </div>
                );
              }}
            />
          </Col>
          <Col span={8} className="menu-list">
            <div style={{fontSize: 20, fontWeight: 'bold'}}>{t('footerMenu')}</div>
            <Divider/>
            <Space>
              {/* <Button onClick={()=>handleAdd(footerData, 0, 2)} type="primary">
                {t('addFirstLevel')}
              </Button> */}
              <Button
                onClick={()=>handleAdd(footerData, 0, 2)}
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
                size="small"
              />
              <span>{t('addFirstLevel')}</span>
            </Space>
            <Tree
              checkable={false}
              defaultExpandAll
              // onSelect={onSelect}
              treeData={footerData}
              titleRender={(node)=>{
                return (
                  <div className="tree-item-box">
                    <span>{node.title}</span>
                    <div className="btn-group">
                      <a onClick={(e)=>{
                        e.preventDefault();
                        if (!node.attributes.related_files) {
                          message.error('没有关联页面，无法预览');
                          return false;
                        }
                        handlePreviewArticle(node.attributes.related_files);
                      }}><EyeOutlined /></a>
                      <a onClick={(e)=>{
                        e.preventDefault();
                        onSelect(node);
                        // console.log(e);
                      }}><EditOutlined /></a>
                      <Popconfirm
                        placement="top"
                        title={t('sureToDelete')}
                        onConfirm={()=>{
                          handleDelete(node);
                        }}
                        okText={t('yes')}
                        cancelText={t('no')}
                      >
                        <a><CloseOutlined /></a>
                      </Popconfirm>
                      {node.key.split('-').length<3?
                    <a onClick={()=>{
                      if (node.key.split('-').length===2) {
                        handleAdd(footerData, 1, 2, node);
                      } else {
                        handleAdd(footerData, 2, 2, node);
                      }
                    }}><PlusOutlined /></a>:null}
                    </div>
                  </div>
                );
              }}
            />
          </Col>
          <Col span={8} className="menu-list">
            <div style={{fontSize: 20, fontWeight: 'bold'}}>{t('sideMenu')}</div>
            <Divider/>
            <Space>
              {/* <Button onClick={()=>handleAdd(sideData, 0, 3)} type="primary">
                {t('addFirstLevel')}
              </Button> */}
              <Button
                onClick={()=>handleAdd(sideData, 0, 3)}
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
                size="small"
              />
              <span>{t('addFirstLevel')}</span>
            </Space>
            <Tree
              checkable={false}
              defaultExpandAll
              // onSelect={onSelect}
              treeData={sideData}
              titleRender={(node)=>{
                return (
                  <div className="tree-item-box">
                    <span>{node.title}</span>
                    <div className="btn-group">
                      <a onClick={(e)=>{
                        e.preventDefault();
                        if (!node.attributes.related_files) {
                          message.error('没有关联页面，无法预览');
                          return false;
                        }
                        handlePreviewArticle(node.attributes.related_files);
                      }}><EyeOutlined /></a>
                      <a onClick={(e)=>{
                        e.preventDefault();
                        onSelect(node);
                        // console.log(e);
                      }}><EditOutlined /></a>
                      <Popconfirm
                        placement="top"
                        title={t('sureToDelete')}
                        onConfirm={()=>{
                          handleDelete(node);
                        }}
                        okText={t('yes')}
                        cancelText={t('no')}
                      >
                        <a><CloseOutlined /></a>
                      </Popconfirm>
                      {node.key.split('-').length<3?
                    <a onClick={()=>{
                      if (node.key.split('-').length===2) {
                        handleAdd(sideData, 1, 3, node);
                      } else {
                        handleAdd(sideData, 2, 3, node);
                      }
                    }}><PlusOutlined /></a>:null}
                    </div>
                  </div>
                );
              }}
            />
          </Col>
        </Row>
        <Modal
          title={t('editMenu')}
          visible={modalVisible}
          onOk={()=>setModalVisible(false)}
          onCancel={()=>setModalVisible(false)}
          footer={null}
          destroyOnClose={true}
          width={640}
        >
          <Form
            form={form}
            {...layout}
            initialValues={{title: currentTreeItem.text}}
            onFinish={(values)=>{
              handleUpdate(values);
            }}
          >
            <Form.Item
              label={t('title')}
              name="title"
              rules={[{required: true, message: 'Please input your username!',
                whitespace: true}]}
              shouldUpdate
            >
              <Input />
            </Form.Item>
            {/* <Form.Item
              label="relate"
              name="relate"
            >
              <Input.TextArea rows={4} />
            </Form.Item> */}
            <Form.Item
              label={t('relateArticle')}
              name="relateArticle"
            >
              <Select style={{width: '100%'}} options={articleList} allowClear onChange={(e)=>{
                if (!e) handleRelateArticle(currentTreeItem.id, '');
                else handleRelateArticle(currentTreeItem.id, e);
              }}>
              </Select>
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Space>
                <Button type="primary" htmlType="submit">
                  {t('confirm')}
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title={t('preview')}
          visible={previewModalVisible}
          onCancel={()=>setPreviewModalVisible(false)}
          destroyOnClose
          width={1200}
          footer={null}
        >
          <Row>
            <Col span={24} style={{height: '100%', overflowY: 'auto'}}>
              <div className="preview-wrapper" dangerouslySetInnerHTML={{__html: htmlData}}></div>
            </Col>
          </Row>
        </Modal>
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

MenuConfig.propTypes = {
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MenuConfig);
