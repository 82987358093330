import React, {useCallback, useEffect, useState} from 'react';
import {Button, Form, Input, message, Modal, Space, Spin, Table, Typography, Upload} from 'antd';
import {DeleteOutlined, EyeOutlined, PaperClipOutlined, PlusOutlined, UploadOutlined} from '@ant-design/icons';
import {DELETEFILES, GETFILES, UPLOAD} from '../../../api/index';
import {get, post} from '../../../api/request';
import {storage} from '@/api/storage';
import {useTranslation} from 'react-i18next';

const UploadFiles = () => {
  const [fileList, setFileList] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [file, setFile] = useState([]);
  const [userInfo] = useState(storage.getData('userInfo') ?? null);
  const [form] = Form.useForm();
  const {t} = useTranslation();
  const tailLayout = {
    wrapperCol: {
      offset: 10,
      span: 16,
    },
  };

  const propsFiles = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    accept: '.pdf,.txt,.xlsx,.xls,.doc,.docx,.doc,.ppt,.pptx',
    customRequest: (info)=>{
      setFile(info.file);
      form.setFieldsValue({
        title: info.file.name,
      });
    },
  };
  const queryFilesList=(data)=>{
    setTableLoading(true);
    get(GETFILES+data, '', userInfo.token).then((res) => {
      setFileList(res.data.data.rows);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(()=>{
      setTableLoading(false);
    });
  };

  const getFilesList = useCallback(
      (setFileList) => {
        setTableLoading(true);
        get(GETFILES+'?type=9&pageNo=1&pageSize=100000', '', userInfo.token).then((res) => {
          setFileList(res.data.data.rows);
        }).catch((error) => {
          message.error({
            content: error.toString(), key: 'netError', duration: 2,
          });
        }).finally(()=>{
          setTableLoading(false);
        });
      },
      [userInfo.token],
  );
  const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 16},
  };
  const onFinish=(value)=>{
    const formData = new FormData();
    formData.append('file', file);
    formData.append('title', value.title);
    setModalLoading(true);
    post(UPLOAD, formData, {
      'Content-Type': 'multipart/form-data',
      'token': userInfo.token,
    }).then((res)=>{
      if (res.data.code === 200) {
        queryFilesList('?pageNo=1&pageSize=10000&type=9');
      }
    }).catch((error)=>{
      Modal.error({
        content: error.message.toString(),
      });
    }).finally(()=>{
      setFile([]);
      setIsModalVisible(false);
      setModalLoading(false);
    });
  };


  const onDelete=(data)=>{
    post(DELETEFILES+data.id, '', {
      'Content-Type': 'application/x-www-form-urlencoded',
      'token': userInfo.token,
    }).then((res)=>{
      if (res.data.code === 200) {
        queryFilesList('?pageNo=1&pageSize=10000&type=9');
      }
    }).catch((error)=>{
      Modal.error({
        content: error.message.toString(),
      });
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
    form.setFieldsValue({
      title: '',
    });
  };


  const handleCancel = () => {
    setIsModalVisible(false);
    setFile([]);
  };
  useEffect(() => {
    if (Object.hasOwnProperty.call(userInfo, 'token') &&
      userInfo.token !== '') {
      // console.log(userInfo.token);
      return false;
    } else {
      history.push('login');
    }
  }, []);

  useEffect(() => {
    getFilesList(setFileList);
  }, [getFilesList]);


  return (
    <div style={{height: '100%'}}>
      <Button
        size="small"
        shape="circle"
        type="primary"
        icon={<PlusOutlined />}
        onClick={showModal}
        style={{marginBottom: 20}}
      />
      <span style={{marginLeft: 10}}>{t('add')}</span>
      {/* 新增编辑弹框*/}
      <Modal
        visible={isModalVisible}
        title={t('file' )+t('upload')}
        footer={null}
        onCancel={handleCancel}
        destroyOnClose
      >
        <Spin spinning={modalLoading}>
          <Form
            form={form}
            {...layout}
            name="basic"
            initialValues={{remember: true}}
            onFinish={onFinish}
          >
            <Form.Item
              label={t('file')}
              name="files"
              rules={[{required: true, message: t('inputFile')}]}
            >
              <Upload
                {...propsFiles}
              >
                <Button icon={<UploadOutlined />}>{t('upload')}</Button>
                {file.name?<p style={{color: '#0298fc'}}>
                  <PaperClipOutlined />&nbsp;&nbsp;{file.name}
                </p>:''}
              </Upload>
            </Form.Item>
            <Form.Item
              label={t('file')+t('title')}
              name="title"
              rules={[{required: true, message: t('inputFileTitle')}]}
            >
              <Input/>
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                {t('submit')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
      {/* 预览弹框*/}

      <Table dataSource={fileList} pagination={{pageSize: 10}} loading={tableLoading}>
        <Table.Column title={'ID'} key={'id'} dataIndex={'id'} />
        <Table.Column title={t('title')} key={'title'} dataIndex={'title'} />
        <Table.Column title={t('linkUrl')} key={'url'} dataIndex="url" render={(text)=>(
          <Typography.Paragraph copyable>{text}</Typography.Paragraph>
        )} />
        <Table.Column title={t('createDate')} key={'createDate'} dataIndex={'createDate'} />
        <Table.Column title={t('action')} key={'Action'} render={(record) => (
          <Space size="middle">
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href={record.url} target="_blank"><EyeOutlined /></a>
            <Button type="link" onClick={()=>onDelete(record)}><DeleteOutlined /></Button>
          </Space>
        )}/>
      </Table>
    </div>
  );
};
export default UploadFiles;
