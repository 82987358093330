/* eslint-disable require-jsdoc */
/* eslint-disable arrow-parens */

/* eslint-disable react/display-name */
import React, {useState, useEffect} from 'react';
import './style.css';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
// import BraftEditor from 'braft-editor';
// import {ContentUtils} from 'braft-utils';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import * as customEditor from '@/api/ckeditor';
// import '@ckeditor/ckeditor5-build-classic/build/translations/zh-cn';
// import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
// import 'braft-editor/dist/index.css';
import {
  Row,
  Col,
  message,
  Space,
  Tooltip,
  Popconfirm,
  Table,
  Spin,
  // Modal,
  // Upload,
  Button,
  Form,
  Input,
  Drawer,
  // Card,
  // Image,
  // Pagination,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {get, post} from '@/api/request';
import {
  GETMULTITEXTLIST,
  ADDMULTITEXT,
  UPDATEMULTITEXT,
  DELETEMULTITEXT,
  UPLOAD,
  // GETALLIMAGES,
} from '@/api/index';
import {storage} from '@/api/storage';
// const layout = {
//   labelCol: {span: 4},
//   wrapperCol: {span: 18},
// };
// const tailLayout = {
//   wrapperCol: {offset: 4, span: 18},
// };
const u = storage.getData('userInfo') ?? null;
class MyUploadAdapter {
  constructor( loader ) {
    this.loader = loader;
  }
  upload() {
    return new Promise((resolve, reject)=>{
      const formData = new FormData();
      this.loader.file.then((r)=>{
        formData.append('file', r);
        post(UPLOAD, formData, {
          'Content-Type': 'multipart/form-data',
          'token': u.token,
        }).then((res)=>{
          message.success(res.data.msg);
          resolve({
            default: res.data.data.original_Image_url,
          });
        }).catch((error) => {
          // message.error({
          //   content: error.toString(), key: 'netError', duration: 2,
          // });
        }).finally(() => null);
      });
    });
  }
  // Aborts the upload process.
  abort() {
  }
}
const MultiText = () => {
  const [userInfo] = useState(storage.getData('userInfo') ?? null);
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const [httpLoading, setHttpLoading] = useState(true);
  const [multiTextList, setMultiTextList] = useState([]);
  // const [editModalVisible, setEditModalVisible] = useState(false);
  const [editDrawerVisible, setEditDrawerVisible] = useState(false);
  const [editorData, setEditorData] = useState('');
  const [currentMultiText, setCurrentMultiText] = useState(null);
  // const [imageList, setImageList] = useState([]);
  // const [imageListPageConfig, setImageListPageConfig] = useState({
  //   pageSize: 20,
  //   total: 0,
  // });
  // const [imageUrl] = useState('');
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('linkUrl'),
      dataIndex: 'linkUrl',
      key: 'linkUrl',
    },
    {
      title: t('title'),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: t('action'),
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title={t('edit')} placement="bottom">
            <a style={{fontSize: 16}} onClick={(e)=>{
              e.preventDefault();
              setCurrentMultiText(record);
              setEditDrawerVisible(true);
              form.setFieldsValue({
                title: record.title,
                author: record.author??'',
                remark: record.remark??'',
              });
              console.log(typeof record.content);
              setEditorData(record.content);
              // setEditorState(BraftEditor.createEditorState(record.content.replace(new RegExp('\u00a0', 'gm'), ' ')));
            }}><EditOutlined /></a>
          </Tooltip>
          <Tooltip title={t('delete')} placement="bottom">
            <Popconfirm title={t('sureToDelete')} placement="top" onConfirm={()=>{
              setHttpLoading(true);
              handleDeleteMultiText(record.id);
            }} okText={t('yes')} cancelText={t('no')}>
              <a style={{fontSize: 16}}><DeleteOutlined /></a>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];
  // const props = {
  //   name: 'file',
  //   multiple: false,
  //   showUploadList: false,
  //   accept: '.png,.jpg,.jpeg',
  //   customRequest: (info)=>{
  //     const formData = new FormData();
  //     formData.append('file', info.file);
  //     post(UPLOAD, formData, {
  //       'Content-Type': 'multipart/form-data',
  //       'token': userInfo.token,
  //     }).then((res)=>{
  //       message.success(res.data.msg);
  //       // setImageUrl();
  //       setEditorState(ContentUtils.insertMedias(editorState, [{
  //         type: 'IMAGE',
  //         url: res.data.data.original_Image_url,
  //       }]));
  //     }).catch((error) => {
  //     }).finally(() => null);
  //   },
  //   onChange: (e)=>{
  //     console.log(e);
  //   },
  // };
  // const rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  //   },
  //   getCheckboxProps: (record) => ({
  //     disabled: record.name === 'Disabled User',
  //     // Column configuration not to be checked
  //     name: record.name,
  //   }),
  // };
  // const uploadHandler = (param) => {
  //   if (!param.file) {
  //     return false;
  //   }
  //   setEditorState(ContentUtils.insertMedias(editorState, [{
  //     type: 'IMAGE',
  //     url: URL.createObjectURL,
  //   }]));
  // };
  // const extendControls = [
  //   {
  //     key: 'custom-modal',
  //     type: 'modal',
  //     text: t('chooseUploadedFile'),
  //     modal: {
  //       id: 'image-modal',
  //       title: t('image'),
  //       children: (
  //         <div style={{width: 600, padding: '0 10px'}}>
  //           <Spin spinning={httpLoading}>
  //             <div className="image-box">
  //               <Card title={t('uploadedImage')} style={{margin: '4px 0'}}>
  //                 {imageList.map((i, index)=>(
  //                   <div key={index} className="image-wapper">
  //                     <Tooltip title={t('choose')}>
  //                       <Image
  //                         style={{margin: 7}}
  //                         preview={false}
  //                         width={100}
  //                         src={i.url}
  //                         onClick={()=>{
  //                           if (editorState.editorState) {
  //                             setEditorState(ContentUtils.insertMedias(editorState.editorState, [{
  //                               type: 'IMAGE',
  //                               url: i.url,
  //                             }]));
  //                           } else {
  //                             setEditorState(ContentUtils.insertMedias(editorState, [{
  //                               type: 'IMAGE',
  //                               url: i.url,
  //                             }]));
  //                           }
  //                         }}
  //                       />
  //                     </Tooltip>
  //                   </div>
  //                 ))}
  //               </Card>
  //               <Pagination
  //                 size="small"
  //                 style={{margin: '20px 10px 0 0', float: 'right'}}
  //                 {...imageListPageConfig}
  //                 onChange={(page)=>{
  //                   setHttpLoading(true);
  //                   handleGetAllImages(page, 8, setImageList);
  //                 }}
  //               />
  //             </div>
  //           </Spin>
  //         </div>
  //       ),
  //     },
  //   },
  //   {
  //     key: 'antd-uploader',
  //     type: 'component',
  //     component: (
  //       <Upload
  //         accept="image/*"
  //         showUploadList={false}
  //         {...props}
  //       >
  //         <button type="button" className="control-item button upload-button" data-title="插入图片">
  //           上传图片
  //         </button>
  //       </Upload>
  //     ),
  //   },
  // ];
  // const controls = [
  //   'headings', 'italic', 'underline', 'link', 'font-size',
  //   'text-color', 'bold', 'text-align',
  // ];
  // const submitContent = async () => {
  //   // const htmlContent = this.state.editorState.toHTML();
  //   // const result = await saveEditorContent(htmlContent);
  // };

  // const handleEditorChange = (editorState) => {
  //   setEditorState({editorState});
  // };
  const handleGetMultiTextList = (set)=>{
    get(GETMULTITEXTLIST+'?pageSize=999', {}, userInfo.token).then((res) => {
      set(res.data.data.rows);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => setHttpLoading(false));
  };
  const handleAddMultiText = (values)=>{
    post(ADDMULTITEXT, {
      title: values.title??'',
      author: values.author??'',
      remark: values.remark??'',
      content: encodeURIComponent(editorData),
    }, {
      'token': userInfo.token,
      'Content-Type': 'application/x-www-form-urlencoded',
    }).then((res) => {
      message.success(res.data.msg);
      setEditDrawerVisible(false);
      handleGetMultiTextList(setMultiTextList);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => null);
  };
  const handleUpdateMultiText = (id, values)=>{
    console.log(editorData);
    post(UPDATEMULTITEXT+id, {
      title: values.title??'',
      author: values.author??'',
      remark: values.remark??'',
      content: encodeURIComponent(editorData),
    }, {
      'token': userInfo.token,
      'Content-Type': 'application/x-www-form-urlencoded',
    }).then((res) => {
      message.success(res.data.msg);
      setCurrentMultiText(null);
      setEditDrawerVisible(false);
      handleGetMultiTextList(setMultiTextList);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => null);
  };
  const handleDeleteMultiText = (id)=>{
    post(DELETEMULTITEXT+id, {}, {
      'token': userInfo.token,
      'Content-Type': 'application/x-www-form-urlencoded',
    }).then((res) => {
      message.success(res.data.msg);
      handleGetMultiTextList(setMultiTextList);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => setHttpLoading(false));
  };
  // const uploadHandler = (param) => {
  //   console.log(param);
  //   console.log(imageUrl);
  //   setEditorState(ContentUtils.insertMedias(editorState, [{
  //     type: 'IMAGE',
  //     url: imageUrl,
  //   }]));
  // };
  // const handleGetAllImages = (pageNo, pageSize=8, set)=>{
  //   get(`${GETALLIMAGES}&pageNo=${pageNo}&pageSize=${pageSize}`, {}, userInfo.token).then((res) => {
  //     set(res.data.data.rows);
  //     setImageListPageConfig({
  //       pageSize: pageSize,
  //       total: res.data.data.total,
  //     });
  //   }).catch((error) => {
  //     message.error({
  //       content: error.toString(), key: 'netError', duration: 2,
  //     });
  //   }).finally(() => setHttpLoading(false));
  // };
  useEffect(() => {
    handleGetMultiTextList(setMultiTextList);
  }, []);
  // useEffect(() => {
  //   handleGetAllImages(1, 8, setImageList);
  // }, []);
  // useEffect(() => {
  //   ClassicEditor
  //       .create(document.querySelector('#editor'))
  //       .catch( (error) => {
  //         console.error( error );
  //       });
  // }, []);
  return (
    <div className="multi-text">
      <Spin spinning={httpLoading}>
        <Row>
          <Col span={12}>
            {/* <div className="btn-box">
              <Button
                size="small"
                type="link"
                icon={<DeleteOutlined />}/>
              {t('delete')}
            </div> */}
            <Button
              size="small"
              shape="circle"
              type="primary"
              icon={<PlusOutlined />}
              onClick={()=>{
                setEditDrawerVisible(true);
              }}/>
            <span style={{marginLeft: 10}}>{t('add')}</span>
          </Col>
        </Row>
        <Row style={{margin: '16px 0'}}>
          <Col span={24}>
            <Table
              bordered
              columns={columns}
              dataSource={multiTextList}
              tableLayout="fixed"
              // rowSelection={{
              //   ...rowSelection,
              // }}
              rowKey={(record)=>record.id}
            />
          </Col>
        </Row>
      </Spin>
      {/* <Modal
        title={t('editMultiText')}
        visible={editModalVisible}
        onCancel={()=>{
          form.resetFields();
          setEditModalVisible(false);
          setEditorState({});
          setCurrentMultiText(null);
        }}
        onOk={()=>{
          form.submit();
        }}
        destroyOnClose
        maskClosable={false}
        width={1200}
      > */}
      <Drawer
        title={t('editComponent')}
        placement="right"
        onClose={() => {
          setEditDrawerVisible(false);
          form.resetFields();
        }}
        visible={editDrawerVisible}
        width={1600}
        destroyOnClose
        footer={(
          <Space>
            <Button type="primary" onClick={()=>{
              // handleUpdateArticle(currentArticle.id);
              form.submit();
            }} loading={httpLoading}>{t('submit')}</Button>
            <Button onClick={()=>{
              setEditDrawerVisible(false);
              form.resetFields();
              setCurrentMultiText(null);
            }}>{t('close')}</Button>
          </Space>
        )}
      >
        <Spin spinning={httpLoading}>
          <Row style={{marginTop: 16}}>
            <Col span={24} style={{padding: 10}}>
              <Form
                form={form}
                layout="inline"
                onFinish={(values) => {
                  setHttpLoading(true);
                  currentMultiText===null?handleAddMultiText(values):handleUpdateMultiText(currentMultiText.id, values);
                }}
              >
                <Form.Item
                  label={t('title')}
                  name="title"
                  rules={[{required: true, message: '必填！'}]}
                  shouldUpdate
                >
                  <Input style={{width: 240}} />
                </Form.Item>
                <Form.Item
                  label={t('author')}
                  name="author"
                  shouldUpdate
                >
                  <Input style={{width: 240}}/>
                </Form.Item>
                <Form.Item
                  label={t('remark')}
                  name="remark"
                  shouldUpdate
                >
                  <Input style={{width: 240}} />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row style={{marginTop: 16}}>
            <Col span={24} style={{padding: 10}}>
              {/* <BraftEditor
                value={editorState}
                onChange={(state)=>{
                  handleEditorChange(state);
                }}
                onSave={()=>submitContent}
                extendControls={extendControls}
                controls={controls}
              /> */}
              <CKEditor
                editor={ ClassicEditor }
                data={editorData}
                onReady={ (editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log( 'Editor is ready to use!', editor );
                  editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                    return new MyUploadAdapter( loader );
                  };
                } }
                onChange={ ( event, editor ) => {
                  const data = editor.getData();
                  console.log( {event, editor, data} );
                  setEditorData(data);
                } }
                onBlur={ ( event, editor ) => {
                  console.log( 'Blur.', editor );
                } }
                onFocus={ ( event, editor ) => {
                  console.log( 'Focus.', editor );
                } }
                config={{
                  toolbar: {
                    items: [
                      'heading',
                      '|',
                      'fontSize',
                      'fontFamily',
                      '|',
                      'bold',
                      'italic',
                      'underline',
                      'strikethrough',
                      'highlight',
                      'fontColor',
                      'fontSize',
                      '|',
                      'alignment',
                      '|',
                      'numberedList',
                      'bulletedList',
                      '|',
                      'indent',
                      'outdent',
                      '|',
                      'todoList',
                      'link',
                      'blockQuote',
                      'imageUpload',
                      'insertTable',
                      '|',
                      'undo',
                      'redo',
                    ],
                  },
                }}
              />
            </Col>
          </Row>
        </Spin>
      </Drawer>
      {/* </Modal> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

MultiText.propTypes = {
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MultiText);
