import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Menu} from 'antd';
// import Icon from '@ant-design/icons';
import {Trans, useTranslation} from 'react-i18next';
import './style.css';
import home from '@/assets/home.png';
import banner from '@/assets/banner.png';
import extra from '@/assets/extra.png';
import menu from '@/assets/menu.png';
import page from '@/assets/page.png';
import multi from '@/assets/multi.png';
import contact from '@/assets/contact.png';

const {SubMenu} = Menu;
const Menus = () => {
  const {t} = useTranslation();
  return (
    <div className="Menus">
      <Menu theme="dark" mode="inline" defaultSelectedKeys={['0']}>
        <Menu.Item key="0">
          <img src={home} alt=""/>
          <Link to="home">
            <Trans>home</Trans>
          </Link>
        </Menu.Item>
        <Menu.Item key="1">
          <img src={multi} alt=""/>
          <Link to="multimedia">
            <Trans>multimedia</Trans>
          </Link>
        </Menu.Item>
        <SubMenu key="sub0" title={t('pageSources')} icon={<img src={page} alt=""/>}>
          <Menu.Item key="02">
            <Link to="multiText">
              {t('multiText')}
            </Link>
          </Menu.Item>
          <Menu.Item key="01" >
            <Link to="article">
              {t('article')}
            </Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="2">
          <img src={banner} alt=""/>
          <Link to="bannerConfig" >
            <Trans>bannerConfig</Trans>
          </Link>
        </Menu.Item>
        <Menu.Item key="3">
          <img src={menu} alt=""/>
          <Link to="menuConfig">
            <Trans>menuConfig</Trans>
          </Link>
        </Menu.Item>
        <Menu.Item key="4">
          <img src={contact} alt=""/>
          <Link to="contactUs">
            {t('contactUs')}
          </Link>
        </Menu.Item>
        <Menu.Item key="5">
          <img src={extra} alt=""/>
          <Link to="extraConfig">
            {t('extraConfig')}
          </Link>
        </Menu.Item>
        {/* <Menu.Item key="4">
          <Link to="extraConfig">
            <Trans>extraConfig</Trans>
          </Link>
        </Menu.Item> */}
      </Menu>
    </div>
  );
};

Menus.propTypes = {
};

export default (withRouter(Menus));
