import React, {useState, useEffect, useCallback} from 'react';
import {Card, message, Modal, Pagination, Spin, Upload} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {UPLOAD, GETPICTURELIST, DELETEFILES} from '../../../api/index';
import {post, get} from '../../../api/request';
import {storage} from '@/api/storage';
import {useTranslation} from 'react-i18next';

const getBase64=(file)=> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};


const UploadPicture = () => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  const [page, setPage]= useState(1);
  const [total, setTotal]=useState(0);
  const [loading, setLoading]=useState(true);
  const [current, setCurrent] =useState(1);
  const [userInfo] = useState(storage.getData('userInfo') ?? null);
  const {t} = useTranslation();
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };


  const getPictureList = useCallback(
      (setFileList) => {
        get(GETPICTURELIST+'?endLine=26&type=1&startLine=0', '', userInfo.token).then((res) => {
          const list = [];
          setTotal(res.data.data.total);
          res.data.data.rows.forEach((item)=>{
            list.push({
              uid: item.id,
              name: item.id,
              status: 'done',
              url: item.url,
            });
          });

          setFileList(list);
        }).catch((error) => {
          message.error({
            content: error.toString(), key: 'netError', duration: 2,
          });
        }).finally(()=>{
          setLoading(false);
        });
      },
      [userInfo.token],
  );
  const queryPictureList=(data)=>{
    setLoading(true);
    get(GETPICTURELIST+data, '', userInfo.token).then((res) => {
      const list = [];
      setTotal(res.data.data.total);
      res.data.data.rows.forEach((item)=>{
        list.push({
          uid: item.id,
          name: item.id,
          status: 'done',
          url: item.url,
        });
      });
      setFileList(list);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(()=>{
      setLoading(false);
    });
  };

  const onPictureDelete=(file)=>{
    const url=DELETEFILES+file.uid;
    post(url, '', {
      'Content-Type': 'application/x-www-form-urlencoded',
      'token': userInfo.token,
    }).then(()=>{
      const num=total-(27*(current-1));
      if (num===0) {
        onPageChange(1, 27);
      } else {
        onPageChange(current, 27);
      }
    }).catch((error)=>{
      Modal.error({
        content: error.message.toString(),
      });
    });
  };

  const props = {
    name: 'file',
    multiple: false,
    showUploadList: true,
    fileList: fileList,
    listType: 'picture-card',
    accept: 'image/*',
    customRequest: (info)=>{
      const pic={
        uid: info.file.uid,
        name: info.file.name,
        status: 'uploading',
        percent: 50,
      };
      const list =[pic, ...fileList];
      setFileList(list);
      const formData = new FormData();
      formData.append('file', info.file);
      post(UPLOAD, formData, {
        'Content-Type': 'multipart/form-data',
        'token': userInfo.token,
      }).then((res)=>{
        if (res.data.code !== 200) {
          list[0]={
            uid: info.file.uid,
            name: info.file.name,
            status: 'error',
          };
          setFileList(list);
        }
      }).catch((error)=>{
        Modal.error({
          content: error.message.toString(),
        });
        list[0]={
          uid: info.file.uid,
          name: info.file.name,
          status: 'error',
        };
        setFileList(list);
      }).finally(()=>{
        onPageChange(1, 27);
      });
    },
  };
  const onPageChange=(page)=>{
    setPage(page);

    const count=((page-1)*27-1);
    const pages=count>0?27:26;
    const data='?endLine='+pages+'&type=1&startLine='+(count>0?count:0);
    setCurrent(page);
    queryPictureList(data);
  };
  useEffect(() => {
    if (Object.hasOwnProperty.call(userInfo, 'token') &&
      userInfo.token !== '') {
      return false;
    } else {
      history.push('login');
    }
  }, []);

  useEffect(() => {
    getPictureList(setFileList);
  }, [getPictureList]);
  return (
    <Spin spinning={loading}>
      <Card style={{height: 384}}>
        <Upload
          {...props}
          onPreview={handlePreview}
          onRemove={onPictureDelete}
          className={page===1?'low imgList':'imgList'}
        >
          {page===1?(<div>
            <PlusOutlined/>
            <div>{t('upload')}</div>
          </div>):''}
        </Upload>

        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={() => setPreviewVisible(false)}
        >
          <img alt="example" style={{width: '100%'}} src={previewImage}/>
        </Modal>

      </Card>
      <Pagination
        showSizeChanger={false}
        pageSize="27"
        current={current}
        style={{
          textAlign: 'right',
          marginTop: 20,
        }}
        defaultCurrent={1}
        total={total+1}
        onChange={onPageChange}/>
    </Spin>
  );
};
export default UploadPicture;
