import React, {useCallback, useEffect, useState} from 'react';
import {Button, Form, Input, message, Modal, Space, Spin, Table, Typography, Upload} from 'antd';
import {
  DeleteOutlined,
  EyeOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import {DELETEFILES, GETFILES, UPLOAD} from '../../../api/index';
import {get, post} from '../../../api/request';
import {storage} from '@/api/storage';
import {useTranslation} from 'react-i18next';
import video from '../../../video.png';

const UploadVideo = () => {
  const [fileList, setFileList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [file, setFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState('');
  const [userInfo] = useState(storage.getData('userInfo') ?? null);
  const [isPreview, setIsPreview] = useState(false);
  const [previewUrl, setPreviewUrl]=useState('');
  const [previewTitle, setPreviewTitle]=useState('');
  const [tableLoading, setTableLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [form] = Form.useForm();

  const {t} = useTranslation();

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{marginTop: 8}}>{t('upload')}</div>
    </div>
  );
  const tailLayout = {
    wrapperCol: {
      offset: 10,
      span: 16,
    },
  };
  const propsImg = {
    name: 'file',
    multiple: true,
    listType: 'picture-card',
    showUploadList: false,
    // fileList,
    accept: 'image/*',
    customRequest: (info)=>{
      const formData = new FormData();
      formData.append('file', info.file);
      setLoading(true);
      post(UPLOAD, formData, {
        'Content-Type': 'multipart/form-data',
        'token': userInfo.token,
      }).then((res)=>{
        if (res.data.code === 200) {
          setLoading(false);
          setThumbnail(res.data.data.thumbnail_url);
        } else {
          Modal.error({
            content: res.data.message.toString(),
          });
        }
      }).catch((error)=>{
        Modal.error({
          content: error.message.toString(),
        });
        setLoading(false);
      });
    },
  };
  const propsVideo = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    accept: '.WebM,.MP4,.Ogg',
    customRequest: (info)=>{
      setFile(info.file);
      form.setFieldsValue({
        title: info.file.name,
      });
    },
  };
  const queryVideoList=(data)=>{
    setTableLoading(true);
    get(GETFILES+data, '', userInfo.token).then((res) => {
      setFileList(res.data.data.rows);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(()=>{
      setTableLoading(false);
    });
  };

  const getVideoList = useCallback(

      (setFileList) => {
        get(GETFILES+'?type=2&pageNo=1&pageSize=100000', '', userInfo.token).then((res) => {
          setFileList(res.data.data.rows);
        }).catch((error) => {
          message.error({
            content: error.toString(), key: 'netError', duration: 2,
          });
        }).finally(()=>{
          setTableLoading(false);
        });
      },
      [userInfo.token],
  );
  const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 16},
  };
  const onFinish=(value)=>{
    // console.log(value);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('title', value.title);
    formData.append('thumbnail', thumbnail);
    setModalLoading(true);
    post(UPLOAD, formData, {
      'Content-Type': 'multipart/form-data',
      'token': userInfo.token,
    }).then((res)=>{
      if (res.data.code === 200) {
        queryVideoList('?pageNo=1&pageSize=10000&type=2');
      }
    }).catch((error)=>{
      Modal.error({
        content: error.message.toString(),
      });
    }).finally(()=>{
      setFile([]);
      setThumbnail('');
      setIsModalVisible(false);
      setModalLoading(false);
    });
  };
  const onPreview=(data)=>{
    setPreviewTitle(data.title);
    setIsPreview(true);
    setPreviewUrl(data.url);
  };

  const onDelete=(data)=>{
    post(DELETEFILES+data.id, '', {
      'Content-Type': 'application/x-www-form-urlencoded',
      'token': userInfo.token,
    }).then((res)=>{
      if (res.data.code === 200) {
        queryVideoList('?pageNo=1&pageSize=10000&type=2');
      }
    }).catch((error)=>{
      Modal.error({
        content: error.message.toString(),
      });
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
    form.setFieldsValue({
      title: '',
    });
  };


  const handleCancel = () => {
    setIsModalVisible(false);
    setFile([]);
    setThumbnail('');
  };
  useEffect(() => {
    if (Object.hasOwnProperty.call(userInfo, 'token') &&
      userInfo.token !== '') {
      // console.log(userInfo.token);
      return false;
    } else {
      history.push('login');
    }
  }, []);

  useEffect(() => {
    getVideoList(setFileList);
  }, [getVideoList]);


  return (
    <div style={{height: '100%'}}>
      <Button
        size="small"
        shape="circle"
        type="primary"
        icon={<PlusOutlined />}
        onClick={showModal}
        style={{marginBottom: 20}}
      />
      <span style={{marginLeft: 10}}>{t('add')}</span>

      {/* 新增编辑弹框*/}
      <Modal
        visible={isModalVisible}
        title={t('video' )+t('upload')}
        footer={null}
        onCancel={handleCancel}
        destroyOnClose
      >
        <Spin spinning={modalLoading}>
          <Form
            form={form}
            {...layout}
            name="basic"
            initialValues={{remember: true}}
            onFinish={onFinish}
          >
            <Form.Item
              label={t('video')}
              name="video"
              rules={[{required: true, message: t('inputVideo')}]}
            >
              <Upload
                {...propsVideo}
              >
                <Button icon={<UploadOutlined />}>{t('upload')}</Button>
                {file.name?<p style={{color: '#0298fc'}}>
                  <VideoCameraOutlined />&nbsp;&nbsp;{file.name}
                </p>:''}
              </Upload>
            </Form.Item>
            <Form.Item
              label={t('video')+t('title')}
              name="title"
              rules={[{required: true, message: t('inputFileTitle')}]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('thumbnail')}
              name="thumbnail"
              rules={[{required: true, message: t('inputThumbnail')}]}
            >
              <Upload
                {...propsImg}
                style={{overflow: 'hidden'}}
              >
                {thumbnail ?
                  <img src={thumbnail} alt="avatar" style={{maxWidth: '100%', maxHeight: '100%'}}/> :
                  uploadButton}
              </Upload>
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                {t('submit')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
      {/* 预览弹框*/}
      <Modal
        visible={isPreview}
        title={previewTitle}
        footer={null}
        onCancel={()=>setIsPreview(false)}
        onOk={()=>{
          setIsPreview(false); setPreviewUrl(''); setPreviewTitle('');
        }}
      >
        <video src={previewUrl} width={470} controls="controls" style={{margin: '0 auto'}}>
          您的浏览器不支持 video 标签。
        </video>
      </Modal>
      <Table dataSource={fileList} pagination={{pageSize: 10}} loading={tableLoading}>
        <Table.Column title={'ID'} key={'id'} dataIndex={'id'} />
        <Table.Column
          title={t('thumbnail')}
          key={'thumbnail'}
          dataIndex={'thumbnail'}
          render={(text)=> (<img src={text||video} alt={t('thumbnail')} width={100} height={100}/>)}
        />
        <Table.Column title={t('title')} key={'title'} dataIndex={'title'} />
        <Table.Column title={t('linkUrl')} key={'url'} dataIndex="url" render={(text)=>(
          <Typography.Paragraph copyable>{text}</ Typography.Paragraph>
        )} />
        <Table.Column title={t('createDate')} key={'createDate'} dataIndex={'createDate'} />
        <Table.Column title={t('action')} key={'Action'} render={(record) => (
          <Space size="middle">
            <Button type="link" onClick={()=>onPreview(record)}><EyeOutlined /></Button>
            <Button type="link" onClick={()=>onDelete(record)}><DeleteOutlined /></Button>
          </Space>
        )}/>
      </Table>
    </div>
  );
};
export default UploadVideo;
