/* eslint-disable react/display-name */
import React, {useState, useEffect} from 'react';
import './style.css';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
// import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Spin,
  Button,
  message,
  Card,
  Form,
  Input,
  Upload,
  Modal,
  Space,
  Image,
  Pagination,
  Tooltip,
  Popconfirm,
  List,
} from 'antd';
import {
  PlusOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import {storage} from '@/api/storage';
import {post, get} from '@/api/request';
import {
  GETBANNERLIST,
  UPLOADBANNER,
  GETALLIMAGES,
  ADDBANNER,
  UPDATEBANNER,
  DELETEBANNER,
  UPDATEBANNERSTATUS,
  SORTBANNER,
} from '@/api/index';
const layout = {
  labelCol: {span: 4},
  wrapperCol: {span: 20},
};
// const tailLayout = {
//   wrapperCol: { offset: 8, span: 16 },
// };
const getBase64 = (img, callback)=>{
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};
const BannerConfig = () => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const [userInfo] = useState(storage.getData('userInfo') ?? null);
  const [httpLoading, setHttpLoading] = useState(true);
  const [bannerList, setBannerList] = useState([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [currentBanner, setCurrentBanner] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [imagesBoxVisible, setImagesBoxVisible] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [imageListPageConfig, setImageListPageConfig] = useState({
    pageSize: 20,
    total: 0,
  });
  const uploadButton = (
    <div style={{marginTop: 8}}>{t('upload')}</div>
  );
  const handleImgChange = (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) =>
        console.log('done'),
      );
    }
  };
  const props = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    accept: '.png,.jpg,.jpeg',
    customRequest: (info)=>{
      const formData = new FormData();
      formData.append('file', info.file);
      post(UPLOADBANNER, formData, {
        'Content-Type': 'multipart/form-data',
        'token': userInfo.token,
      }).then((res)=>{
        message.success(res.data.msg);
        setImageUrl(res.data.data.original_Image_url);
        form.setFieldsValue({
          bannerUrl: res.data.data.original_Image_url,
        });
      }).catch((error) => {
        message.error({
          content: error.toString(), key: 'netError', duration: 2,
        });
      }).finally(() => null);
    },
    onChange: (info)=>{
      handleImgChange(info);
    },
  };
  const handleGetBannerList = (set)=>{
    post(GETBANNERLIST, {status: -1}, {
      'token': userInfo.token,
      'Content-Type': 'application/x-www-form-urlencoded',
    }).then((res) => {
      const data = [...res.data.data];
      data.sort((a, b)=>{
        if (a.status<b.status) {
          return 1;
        }
        if (a.status>b.status) {
          return -1;
        }
        return 0;
      });
      set(data);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => setHttpLoading(false));
  };
  const handleGetAllImages = (pageNo, pageSize=14, set)=>{
    get(`${GETALLIMAGES}&pageNo=${pageNo}&pageSize=${pageSize}`, {}, userInfo.token).then((res) => {
      set(res.data.data.rows);
      setImageListPageConfig({
        pageSize: pageSize,
        total: res.data.data.total,
      });
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => null);
  };
  const handleAddBanner = (values)=>{
    const data = {...values};
    Object.keys(data).map((key)=>{
      return !data[key]?data[key]='':data[key];
    });
    post(ADDBANNER, {
      ...data,
      sort: bannerList.length+1,
    }, {
      'token': userInfo.token,
      'Content-Type': 'application/x-www-form-urlencoded',
    }).then((res) => {
      message.success(res.data.msg);
      setEditModalVisible(false);
      handleGetBannerList(setBannerList);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => setHttpLoading(false));
  };
  const handleUpdateBanner = (values, item)=>{
    const data = {...values};
    Object.keys(data).map((key)=>{
      return !data[key]?data[key]='':data[key];
    });
    post(UPDATEBANNER, {
      ...data,
      sort: item.sort,
      id: item.id,
    }, {
      'token': userInfo.token,
      'Content-Type': 'application/x-www-form-urlencoded',
    }).then((res) => {
      message.success(res.data.msg);
      setEditModalVisible(false);
      handleGetBannerList(setBannerList);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => setHttpLoading(false));
  };
  const handleDeleteBanner = (id)=>{
    post(DELETEBANNER+id, {}, {
      'token': userInfo.token,
      'Content-Type': 'application/x-www-form-urlencoded',
    }).then((res) => {
      message.success(res.data.msg);
      handleGetBannerList(setBannerList);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => setHttpLoading(false));
  };
  const handleChangeBannerStatus = (id, status)=>{
    post(UPDATEBANNERSTATUS, {
      id: id,
      status: status,
    }, {
      'token': userInfo.token,
      'Content-Type': 'application/x-www-form-urlencoded',
    }).then((res) => {
      message.success(res.data.msg);
      handleGetBannerList(setBannerList);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => setHttpLoading(false));
  };
  const handleSortBanner = (sortArr, index, type)=>{
    const data = sortArr.map((item)=>item.id);
    if (type===2) {
      [data[index], data[index+1]] = [data[index+1], data[index]];
    } else {
      [data[index-1], data[index]] = [data[index], data[index-1]];
    }
    post(SORTBANNER, {
      params: data,
    }, {
      'token': userInfo.token,
      'Content-Type': 'application/json',
    }).then((res) => {
      message.success(res.data.msg);
      handleGetBannerList(setBannerList);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => setHttpLoading(false));
  };
  useEffect(() => {
    handleGetBannerList(setBannerList);
  }, []);
  useEffect(() => {
    handleGetAllImages(1, 14, setImageList);
  }, []);
  return (
    <div className="banner-config">
      <Spin spinning={httpLoading}>
        <Row>
          <Col span={12}>
            <Button
              size="small"
              shape="circle"
              type="primary"
              icon={<PlusOutlined />}
              onClick={()=>{
                form.resetFields();
                setImageUrl('');
                setImagesBoxVisible(false);
                setEditModalVisible(true);
                setCurrentBanner(null);
              }}/>
            <span style={{marginLeft: 10}}>{t('add')}</span>
          </Col>
        </Row>
        <div className="card-wapper" style={{marginTop: 20}}>
          {bannerList.map((item, index)=> (
            <div key={item.id} style={{width: 420, marginBottom: 20}}>
              <Card
                style={{width: 400}}>
                <Row>
                  <Col span={24}>
                    {item.status===1?(
                      <div style={{marginBottom: 10, height: 24, lineHeight: '24px'}}>
                        <div style={{float: 'left', color: '#1890ff'}}>{t('onlineAlready')}</div>
                        <div style={{float: 'right'}}>{t('updateTime')} : {item.updateDate}</div>
                      </div>
                    ):<div style={{marginBottom: 10, height: 24}}></div>}
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Image
                      width={'100%'}
                      height={128}
                      src={item.bannerUrl}
                    />
                  </Col>
                </Row>
                <Row style={{marginTop: 20}}>
                  <Col span={24}>
                    <Space direction="vertical" style={{width: '100%'}}>
                      {/* <Input value={item.title} disabled/>
                      <Input value={item.imageAlias} disabled/>
                      <Input value={item.bannerUrl} disabled/>
                      <Input value={item.linkUrl} disabled/>
                      <Input value={item.remark} disabled/>
                      <Input value={item.promptInfo} disabled/> */}
                      <List bordered>
                        {/* <List.Item>
                          <span className="list-item">{item.linkUrl}</span>
                        </List.Item> */}
                        <List.Item>
                          {item.title===''?<span className="list-item">&nbsp;</span>:
                          <span className="list-item">{item.title}</span>}
                        </List.Item>
                        <List.Item>
                          {item.remark===''?<span className="list-item">&nbsp;</span>:
                          <span className="list-item">{item.remark}</span>}
                        </List.Item>
                        <List.Item>
                          {/* <span className="list-item">{item.imageAlias===''?'未填写':item.imageAlias}</span> */}
                          {item.imageAlias===''?<span className="list-item">&nbsp;</span>:
                          <span className="list-item">{item.imageAlias}</span>}
                        </List.Item>
                        <List.Item>
                          {/* <span className="list-item">{item.promptInfo===''?'未填写':item.promptInfo}</span> */}
                          {item.promptInfo===''?<span className="list-item">&nbsp;</span>:
                          <span className="list-item">{item.promptInfo}</span>}
                        </List.Item>
                        <List.Item>
                          {/* <span className="list-item">{item.linkUrl===''?'未填写':item.linkUrl}</span> */}
                          {item.linkUrl===''?<span className="list-item">&nbsp;</span>:
                          <span className="list-item">{item.linkUrl}</span>}
                        </List.Item>
                      </List>
                    </Space>
                  </Col>
                </Row>
                <Row style={{marginTop: 20}} justify="end">
                  <Col span={8} style={(index<5&&item.status===1)?null:{visibility: 'hidden'}}>
                    <span>{t('order')} : </span>
                    {index!==0?<Button
                      style={{marginLeft: 5}}
                      size="small"
                      type="primary"
                      icon={<LeftOutlined />}
                      onClick={()=>{
                        setHttpLoading(true);
                        handleSortBanner(bannerList, index, 1);
                      }}/>:null}
                    {index!==4?<Button
                      style={{marginLeft: 5}}
                      size="small"
                      type="primary"
                      icon={<RightOutlined />}
                      onClick={()=>{
                        setHttpLoading(true);
                        handleSortBanner(bannerList, index, 2);
                      }}/>:null}
                  </Col>
                  <Col span={16} style={{
                    'display': 'flex',
                    'flexDirection': 'row-reverse',
                  }}>
                    <Space>
                      <Popconfirm title={t('sureToDelete')} placement="top" onConfirm={()=>{
                        handleDeleteBanner(item.id);
                      }} okText={t('yes')} cancelText={t('no')}>
                        <Button
                          size="small"
                          type="primary"
                        >{t('delete')}</Button>
                      </Popconfirm>
                      {/* <Button
                        size="small"
                        type="text"
                        onClick={()=>{
                          handleDeleteBanner(item.id);
                        }}>{t('delete')}</Button> */}
                      {(index<bannerList.filter((i)=>i.status===1).length)?<Button
                        size="small"
                        type="primary"
                        onClick={()=>{
                          handleChangeBannerStatus(item.id, 0);
                        }}>
                        {t('offline')}
                      </Button>:bannerList.filter((i)=>i.status===1).length<5?<Button
                        size="small"
                        type="primary"
                        onClick={()=>{
                          handleChangeBannerStatus(item.id, 1);
                        }}>
                        {t('online')}
                      </Button>:null}
                      <Button
                        size="small"
                        type="primary"
                        onClick={()=>{
                          setCurrentBanner(item);
                          setEditModalVisible(true);
                          form.setFieldsValue({
                            title: item.title,
                            imageAlias: item.imageAlias,
                            bannerUrl: item.bannerUrl,
                            linkUrl: item.linkUrl,
                            remark: item.remark,
                            promptInfo: item.promptInfo,
                          });
                          setImageUrl(item.bannerUrl);
                        }}>{t('edit')}</Button>
                    </Space>
                  </Col>
                </Row>
              </Card>
            </div>))}
        </div>
      </Spin>
      <Modal
        title={t('edit')}
        visible={editModalVisible}
        onCancel={()=>{
          setEditModalVisible(false);
          setImagesBoxVisible(false);
          form.resetFields();
          setImageUrl('');
          setCurrentBanner(null);
        }}
        onOk={()=>{
          form.submit();
        }}
        destroyOnClose
        maskClosable={false}
        width={1200}
        footer={[
          <Button key="submit" type="primary" onClick={()=>{
            form.submit();
          }}>
            {t('submit')}
          </Button>,
          <Button key="back" onClick={()=>{
            setEditModalVisible(false);
            setImagesBoxVisible(false);
            form.resetFields();
            setImageUrl('');
            setCurrentBanner(null);
          }}>
            {t('close')}
          </Button>,
        ]}
      >
        <Row>
          <Col span={24} style={{height: '100%', overflowY: 'auto'}}>
            <Form
              form={form}
              {...layout}
              onFinish={(values) => {
                currentBanner===null?handleAddBanner(values):handleUpdateBanner(values, currentBanner);
              }}
            >
              <Form.Item style={{height: 104}} label={t('bannerImage')}
                rules={[{required: true, message: t('required')}]}>
                <Space>
                  <Upload
                    {...props}
                    listType="picture-card"
                    showUploadList={false}
                  >
                    {imageUrl?<img
                      src={imageUrl}
                      alt="avatar"
                      style={{maxWidth: 102, maxHeight: 102}} /> : uploadButton}
                  </Upload>
                  {!imagesBoxVisible?<Button type="primary" style={{margin: '36px 0 0 120px'}} onClick={()=>{
                    setImagesBoxVisible(true);
                  }}>{t('chooseImage')}</Button>:
                  <Button type="primary" style={{margin: '36px 0 0 120px'}} onClick={()=>{
                    setImagesBoxVisible(false);
                  }}>{t('close')}</Button>}
                </Space>
              </Form.Item>
              {imagesBoxVisible?(
              <div className="image-box">
                <Card title={t('uploadedImage')} style={{width: '83.5%', marginLeft: '16.5%'}}>
                  {imageList.map((item, index)=>(
                    <div key={index} className="image-wapper">
                      <Tooltip title={t('choose')}>
                        <img
                          style={{maxWidth: 100, maxHeight: 100}}
                          src={item.url}
                          onClick={()=>{
                            setImageUrl(item.url);
                            form.setFieldsValue({
                              bannerUrl: item.url,
                            });
                            setImagesBoxVisible(false);
                          }}
                        />
                      </Tooltip>
                    </div>
                  ))}
                </Card>
                <Pagination
                  size="small"
                  showSizeChanger={false}
                  style={{margin: '20px 10px 0 0', float: 'right'}}
                  {...imageListPageConfig}
                  onChange={(page)=>{
                    handleGetAllImages(page, 14, setImageList);
                  }}
                />
              </div>):null}
              <Form.Item
                label={t('bannerUrl')}
                name="bannerUrl"
                rules={[{required: true, message: t('needImage')}]}
                shouldUpdate
              >
                <Input placeholder={t('inputBannerUrl')} disabled style={{color: 'rgba(0,0,0,.85)'}}/>
              </Form.Item>
              <Form.Item
                label={t('bannerTitle')}
                name="title"
                shouldUpdate
              >
                <Input style={{width: '100%'}} placeholder={t('inputTitle')} maxLength={100}/>
              </Form.Item>
              <Form.Item
                label={t('bannerRemark')}
                name="remark"
                shouldUpdate
              >
                <Input.TextArea autoSize style={{width: '100%'}}
                  placeholder={t('inputRemark')} rows={6} maxLength={500}/>
              </Form.Item>
              <Form.Item
                label={t('bannerImageAlias')}
                name="imageAlias"
                shouldUpdate
              >
                <Input style={{width: '100%'}} placeholder={t('inputImageAlias')} maxLength={255}/>
              </Form.Item>
              <Form.Item
                label={t('promptInfo')}
                name="promptInfo"
                shouldUpdate
              >
                <Input style={{width: '100%'}} placeholder={t('inputPromptInfo')} maxLength={100}/>
              </Form.Item>
              <Form.Item
                label={t('bannerLinkUrl')}
                name="linkUrl"
                shouldUpdate
              >
                <Input style={{width: '100%'}} placeholder={t('inputLinkUrl')} maxLength={100}/>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

BannerConfig.propTypes = {
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BannerConfig);
