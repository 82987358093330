/* eslint-disable react/display-name */
import React, {useEffect, useState, useCallback} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Row, Col, Form, Button, Divider, Upload, Popconfirm, Card, Pagination, Select,
  Input, message, Spin, Drawer, Space, Modal, Tooltip, Table, Radio, Image,
} from 'antd';
import {EditOutlined, DeleteOutlined, PlusOutlined, UploadOutlined, PlusCircleOutlined,
  EyeOutlined, SendOutlined, FileTextOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {storage} from '@/api/storage';
import './style.css';
import Template from '@/components/template';
import {
  get,
  post,
  remove,
  update,
} from '@/api/request';
import {
  GETMODEL,
  GETCONTENT,
  GETPAGEMODULES,
  ADDARTICLEMODULE,
  // GETPAGELIST,
  UPDATEPAGEMODULE,
  GETARTICLELIST,
  ADDARTICLE,
  DELETEARTICLE,
  DELETEARTICLEMODULE,
  UPDATEARTICLEMODULE,
  SAVEARTICLEMODULE,
  PUBLISHARTICLE,
  GETARTICLEMODULES,
  PREVIEWPAGE,
  UPLOAD,
  GETALLIMAGES,
  GETALLVIDEOS,
  GETMULTITEXTLIST,
} from '@/api/index';
import {modelForm} from '../../modelForms/Forms';
const layout = {
  labelCol: {span: 4},
  wrapperCol: {span: 18},
};
const tailLayout = {
  wrapperCol: {offset: 4, span: 18},
};
const Article = ({history}) => {
  const {t} = useTranslation();
  const [httpLoading, setHttpLoading] = useState(false);
  const [userInfo] = useState(storage.getData('userInfo') ?? null);
  const [templateList, setTemplateList] = useState([]);
  const [moduleList, setModuleList] = useState([]);
  const [radioType, setRadioType] = useState(-1);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [currentModule, setCurrentModule] = useState({moduleId: 0});
  const [currentModuleIdx, setCurrentModuleIdx] = useState(-1);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [articleList, setArticleList] = useState([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [currentArticle, setCurrentArticle] = useState({});
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  const [htmlData, setHtmlData] = useState('');
  const [currentImage, setCurrentImage] = useState('');
  const [currentVideo, setCurrentVideo] = useState('');
  const [imagesBoxVisible, setImagesBoxVisible] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [imageListPageConfig, setImageListPageConfig] = useState({
    pageSize: 20,
    total: 0,
  });
  const [videoBoxVisible, setVideoBoxVisible] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const [videoListPageConfig, setVideoListPageConfig] = useState({
    pageSize: 20,
    total: 0,
  });
  const [multiTextList, setMultiTextList] = useState([]);
  const [form]=Form.useForm();
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('linkUrl'),
      dataIndex: 'linkUrl',
      key: 'linkUrl',
    },
    {
      title: t('title'),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: t('status'),
      key: 'status',
      render: (text, record)=>{
        if (record.status===0) {
          return (
            <span style={{color: '#f5222d'}}>{t('unpublished')}</span>
          );
        }
        return (
          <span style={{color: '#52c41a'}}>{t('published')}</span>
        );
      },
    },
    {
      title: t('action'),
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title={t('edit')} placement="bottom">
            <a onClick={(e)=>{
              e.preventDefault();
              setHttpLoading(true);
              setDrawerVisible(true);
              getArticleModules(record.id);
              setCurrentArticle(record);
            }}><EditOutlined /></a>
          </Tooltip>
          <Tooltip title={t('preview')} placement="bottom">
            <a onClick={(e)=>{
              e.preventDefault();
              handlePreviewPage(record.id);
            }}><EyeOutlined /></a>
          </Tooltip>
          <Tooltip title={t('delete')} placement="bottom">
            <Popconfirm title={t('sureToDelete')} placement="top" onConfirm={()=>{
              handleDeletePage(record.id);
            }} okText={t('yes')} cancelText={t('no')}>
              <a><DeleteOutlined /></a>
            </Popconfirm>
          </Tooltip>
          {record.status===0?<Tooltip title={t('publish')} placement="bottom">
            <Popconfirm title={t('sureToPublish')} placement="top" onConfirm={()=>{
              handlePublishArticle(record.id);
            }} okText={t('yes')} cancelText={t('no')}>
              <a><SendOutlined /></a>
            </Popconfirm>
          </Tooltip>:null}
        </Space>
      ),
    },
  ];
  const imageUploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    accept: '.png,.jpg,.jpeg',
    customRequest: (info)=>{
      const formData = new FormData();
      formData.append('file', info.file);
      setHttpLoading(true);
      post(UPLOAD, formData, {
        'Content-Type': 'multipart/form-data',
        'token': userInfo.token,
      }).then((res)=>{
        message.success(res.data.msg);
        form.setFieldsValue({
          [currentImage.name]: res.data.data.original_Image_url,
        });
      }).catch((error) => {
        message.error({
          content: error.toString(), key: 'netError', duration: 2,
        });
      }).finally(() => setHttpLoading(false));
    },
    onChange: (e)=>{
      console.log(e);
    },
  };
  const videoUploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    accept: '.mp4',
    customRequest: (info)=>{
      const formData = new FormData();
      formData.append('file', info.file);
      setHttpLoading(true);
      post(UPLOAD, formData, {
        'Content-Type': 'multipart/form-data',
        'token': userInfo.token,
      }).then((res)=>{
        message.success(res.data.msg);
        form.setFieldsValue({
          [currentVideo.name]: res.data.data.original_Image_url,
        });
      }).catch((error) => {
        message.error({
          content: error.toString(), key: 'netError', duration: 2,
        });
      }).finally(() => setHttpLoading(false));
    },
    onChange: (e)=>{
      console.log(e);
    },
  };
  const getModuleList = useCallback(
      (setDataFn) => {
        get(GETMODEL+'?pageSize=99', {}, userInfo.token).then((res) => {
          setDataFn(res.data.data);
        }).catch((error) => {
          message.error({
            content: error.toString(), key: 'netError', duration: 2,
          });
        }).finally(() => setHttpLoading(false));
      },
      [userInfo.token],
  );
  const handleAddContent= (type, current, style) => {
    const t = [...templateList];
    if (type===1) {
      post(ADDARTICLEMODULE+currentArticle.id, {
        params: {
          moduleId: current.id,
          position: (t.length+2)*10,
        },
      }, {
        'token': userInfo.token,
      }).then((res) => {
        get(`${GETCONTENT}/${current.id}`, {}, userInfo.token).then((response) => {
          message.success(res.data.msg);
          t.push({
            key: `template_${t.length}`,
            component: <Template key={`template_${t.length}`} el={response.data.data} />,
            moduleId: current.id,
            style,
            diverseId: 0,
            id: res.data.data,
          });
          setTemplateList(t);
        }).catch((error) => {
          message.error({
            content: error.toString(), key: 'netError', duration: 2,
          });
        }).finally(() => setHttpLoading(false));
      }).catch((error) => {
        message.error({
          content: error.toString(), key: 'netError', duration: 2,
        });
      }).finally(() => null);
    } else {
      post(ADDARTICLEMODULE+currentArticle.id, {
        params: {
          moduleId: 1,
          position: (t.length+2)*10,
          diverseId: current.id,
        },
      }, {
        'token': userInfo.token,
      }).then((res) => {
        t.push({
          key: `template_${t.length}`,
          component: <Template key={`template_${t.length}`}
            el={current.content.replace(new RegExp('style', 'gm'), ' style')} />,
          moduleId: 1,
          style,
          diverseId: current.id,
          id: res.data.data,
        });
        setTemplateList(t);
      }).catch((error) => {
        message.error({
          content: error.toString(), key: 'netError', duration: 2,
        });
      }).finally(() => null);
    }
  };
  const handleAddPage = (values)=>{
    post(ADDARTICLE, values, {
      'token': userInfo.token,
      'Content-Type': 'application/json',
    }).then((res) => {
      setAddModalVisible(false);
      message.success(res.data.msg);
      getArticleList(radioType, setArticleList);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => null);
  };
  const handleDeletePage = (id)=>{
    remove(`${DELETEARTICLE}${id}`, {}, userInfo.token).then((res) => {
      message.success(res.data.msg);
      getArticleList(radioType, setArticleList);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => null);
  };
  const getPageModules = (current)=>{
    console.log(current);
    const f = modelForm.find((item)=>current.moduleId===item.id).data;
    const data = f.map((item)=>item.name);
    post(`${GETPAGEMODULES}${current.id}`, {
      keys: data,
    }, {
      'token': userInfo.token,
      'Content-Type': 'application/json',
    }).then((res) => {
      form.setFieldsValue(res.data.data);
      setEditModalVisible(true);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => setHttpLoading(false));
  };
  const getArticleList = (type, set)=>{
    get(`${GETARTICLELIST}${type}`, {}, userInfo.token).then((res) => {
      set(res.data.data);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => null);
  };
  const getArticleModules = (id)=>{
    get(`${GETARTICLEMODULES}${id}`, {}, userInfo.token).then((res) => {
      const data = [...res.data.data];
      const t = [];
      data.forEach((item, index)=>{
        t.push({
          key: `template_${index}`,
          component:
          <Template key={`template_${t.length}`} el={item.context.replace(new RegExp('style', 'gm'), ' style')} />,
          style: {position: 'relative', border: 'solid 1px #d9d9d9'},
          id: item.id,
          moduleId: item.moduleId,
          diverseId: item.relatedDiverseId,
          data: {},
        });
      });
      setTemplateList(t);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => {
      setHttpLoading(false);
    });
  };
  const handleAddArticleModules = (menuId, list)=>{
    const params = [];
    for (let i = 0; i < list.length; i++) {
      let position = 0;
      if (list[i].position===1) {
        position = (i+1)*10;
      } else {
        position = (i+1)*10 + 1;
        if (i-1>0 && list[i-1].position===2) {
          position = (i)*10 + 2;
        }
      }
      params.push({
        id: 0,
        moduleId: list[i].id,
        position: position,
      });
    }
    post(`${UPDATEPAGEMODULE}${menuId}`, {params: params}, {
      'token': userInfo.token,
      'Content-Type': 'application/json',
    }).then((res) => {
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => null);
  };
  const handlePreviewPage = (id)=>{
    get(`${PREVIEWPAGE}${id}`, {}, userInfo.token).then((res) => {
      setHtmlData(res.data.data);
      setPreviewModalVisible(true);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => null);
  };
  const handleDeleteModule = (index, id)=>{
    const t = [...templateList];
    remove(`${DELETEARTICLEMODULE}${id}`, {}, userInfo.token).then((res) => {
      message.success(res.data.msg);
      t.splice(index, 1);
      setTemplateList(t);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => setHttpLoading(false));
  };
  const handleUpdateModule = (index, values)=>{
    update(UPDATEARTICLEMODULE, {params: {
      ...values,
      pageModuleId: currentModule.id,
      position: (index+1)*10,
    }}, {
      'token': userInfo.token,
      'Content-Type': 'application/json',
    }).then((res) => {
      get(`${GETARTICLEMODULES}${currentArticle.id}`, {}, userInfo.token).then((res) => {
        const data = [...res.data.data];
        const t = [...templateList];
        const item = data[index];
        t[index] = {
          key: `template_${index}`,
          component:
          <Template key={`template_${t.length}`} el={item.context.replace(new RegExp('style', 'gm'), ' style')} />,
          style: {position: 'relative', border: 'solid 1px #d9d9d9'},
          id: item.id,
          moduleId: item.moduleId,
          diverseId: item.diverseId??0,
          data: {},
        };
        setTemplateList(t);
        message.success('Success');
      }).catch((error) => {
        message.error({
          content: error.toString(), key: 'netError', duration: 2,
        });
      }).finally(() => {
        setEditModalVisible(false);
        setHttpLoading(false);
      });
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => null);
  };
  const handleSortModule = (sortArr, index, type)=>{
    const data = [...sortArr];
    if (type===2) {
      [data[index], data[index+1]] = [data[index+1], data[index]];
    } else {
      [data[index-1], data[index]] = [data[index], data[index-1]];
    }
    setTemplateList(data);
  };
  const handleUpdateArticle = (id)=>{
    const params = [];
    for (let i = 0; i < templateList.length; i++) {
      if (templateList[i].moduleId!==1) {
        params.push({
          ...templateList[i].data,
          moduleId: templateList[i].moduleId,
          position: (i+1)*10,
          id: templateList[i].id??templateList[i].id,
        });
      } else {
        params.push({
          ...templateList[i].data,
          moduleId: templateList[i].moduleId,
          position: (i+1)*10,
          id: templateList[i].id??templateList[i].id,
          diverseId: templateList[i].diverseId,
        });
      }
    }
    post(`${SAVEARTICLEMODULE}${id}`, {params: params}, {
      'token': userInfo.token,
      'Content-Type': 'application/json',
    }).then((res) => {
      message.success(res.data.msg);
      setDrawerVisible(false);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => null);
  };
  const handleGetAllImages = (pageNo, pageSize=8, set)=>{
    get(`${GETALLIMAGES}&pageNo=${pageNo}&pageSize=${pageSize}`, {}, userInfo.token).then((res) => {
      set(res.data.data.rows);
      setImageListPageConfig({
        pageSize: pageSize,
        total: res.data.data.total,
      });
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => null);
  };
  const handleGetAllVideos = (pageNo, pageSize=8, set)=>{
    get(`${GETALLVIDEOS}&pageNo=${pageNo}&pageSize=${pageSize}`, {}, userInfo.token).then((res) => {
      set(res.data.data.rows);
      setVideoListPageConfig({
        pageSize: pageSize,
        total: res.data.data.total,
      });
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => null);
  };
  const handleGetMultiTextList = (set)=>{
    get(GETMULTITEXTLIST+'?pageSize=999', {}, userInfo.token).then((res) => {
      const data = res.data.data.rows.map((item)=>{
        return {
          label: item.title,
          value: item.id,
          data: item,
        };
      });
      set(data);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => setHttpLoading(false));
  };
  const handlePublishArticle = (id)=>{
    update(PUBLISHARTICLE+id, {}, {
      'token': userInfo.token,
      'Content-Type': 'application/json',
    }).then((res) => {
      message.success(res.data.msg);
      getArticleList(radioType, setArticleList);
    }).catch((error) => {
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(() => null);
  };
  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(userInfo, 'token') &&
    userInfo.token !== '') {
      return false;
    } else {
      history.push('login');
    }
    return () => {
    };
  }, [userInfo, history]);
  useEffect(() => {
    handleGetAllImages(1, 8, setImageList);
  }, []);
  useEffect(() => {
    handleGetAllVideos(1, 8, setVideoList);
  }, []);
  useEffect(() => {
    handleGetMultiTextList(setMultiTextList);
  }, []);
  useEffect(() => {
    getArticleList(-1, setArticleList);
  }, [setArticleList]);
  return (
    <div className="article">
      <Spin spinning={httpLoading}>
        <Row style={{margin: '16px 0'}}>
          <Col span={2}>
            <div className="btn-box">
              <Button
                size="small"
                shape="circle"
                type="primary"
                icon={<PlusOutlined />}
                onClick={()=>{
                  setAddModalVisible(true);
                }}/>
              {t('add')}
            </div>
          </Col>
          <Col span={4}>
            <Radio.Group onChange={(e)=>{
              getArticleList(e.target.value, setArticleList);
              setRadioType(e.target.value);
            }} defaultValue={-1} buttonStyle="solid" name="radiogroup">
              <Radio.Button value={-1}>{t('all')}</Radio.Button>
              <Radio.Button value={1}>{t('published')}</Radio.Button>
              <Radio.Button value={0}>{t('unpublished')}</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
        <Row style={{margin: '16px 0'}}>
          <Col span={24}>
            <Table
              bordered
              columns={columns}
              dataSource={articleList}
              tableLayout="fixed"
              rowKey={(record)=>record.id}
            />
          </Col>
        </Row>
      </Spin>
      <Divider />
      <Drawer
        title={t('editComponent')}
        placement="right"
        onClose={() => {
          setDrawerVisible(false);
          setModuleList([]);
          setTemplateList([]);
        }}
        visible={drawerVisible}
        width={1600}
        destroyOnClose
        afterVisibleChange={(visible)=>{
          if (visible) {
            setHttpLoading(true);
            getModuleList(setModuleList);
          }
        }}
        footer={(
          <Space>
            <Button type="primary" onClick={()=>{
              handleUpdateArticle(currentArticle.id);
            }}>{t('submit')}</Button>
            <Button onClick={()=>{
              setDrawerVisible(false);
            }}>{t('close')}</Button>
          </Space>
        )}
      >
        <Spin spinning={httpLoading}>
          <Row style={{margin: 16}} className="page-container">
            <Col span={4} style={{padding: 10, height: '100%', overflowY: 'auto'}}>
              {moduleList.map((item)=>{
                if (item.id!==1) {
                  return (
                    <div className="item-wrapper" key={item.id}>
                      <Image src={item.moduleImage} width={226} height={152} preview={false}
                        fallback="http://124.71.9.188:10001/image/logo.png"
                      />
                      <div className="item-add-wrapper" onClick={()=>{
                        setHttpLoading(true);
                        handleAddContent(1, item, {
                          position: 'relative',
                          clear: 'both',
                          overflowY: 'auto',
                          border: 'solid 1px #d9d9d9',
                        });
                      }}>
                        <div className="item-add-btn" >
                          <span style={{marginRight: 8}}>{t('add')}</span>
                          <PlusCircleOutlined style={{fontSize: 16, marginTop: 6}}/>
                        </div>
                      </div>
                      <div className="item-title-wrapper">
                        <div className="item-title">
                          {item.moduleName}
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="multi-wrapper">
                      <div className="multi-img">
                        <FileTextOutlined style={{fontSize: 64}}/>
                      </div>
                      <div className="multi-select">
                        <Select
                          placeholder="选择富文本"
                          style={{width: 120}}
                          options={multiTextList}
                          bordered={false}
                          onChange={(e)=>{
                            const i = multiTextList.find((inner)=>inner.value===e);
                            handleAddContent(2, i.data, {
                              position: 'relative',
                              clear: 'both',
                              overflowY: 'auto',
                              border: 'solid 1px #d9d9d9',
                            });
                          }}>
                        </Select>
                      </div>
                    </div>
                  );
                }
              })}
            </Col>
            <Col span={20} style={{height: '100%', overflowY: 'auto'}}>
              <div style={{padding: '0 20px'}}>
                {templateList.map((item, index) => (
                  <div style={item.style} key={index}>
                    <div className="action-group">
                      {index!==0?<Button
                        size="small"
                        onClick={() => {
                          handleSortModule(templateList, index, 1);
                        }}>{t('up')}</Button>:null}
                      {index!==templateList.length-1?<Button
                        size="small"
                        onClick={() => {
                          handleSortModule(templateList, index, 2);
                        }}>{t('down')}</Button>:null}
                      <Button
                        size="small"
                        danger
                        onClick={() => {
                          setHttpLoading(true);
                          handleDeleteModule(index, item.id);
                        }}>{t('delete')}</Button>
                      {item.moduleId!==1?<Button
                        size="small"
                        onClick={() => {
                          setCurrentModule(item);
                          setCurrentModuleIdx(index);
                          getPageModules(item);
                          setHttpLoading(true);
                        }}
                        type="primary">{t('edit')}</Button>:null}
                    </div>
                    <div>{item.component}</div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Spin>
      </Drawer>
      <Modal
        title={t('addNewPage')}
        visible={addModalVisible}
        onCancel={()=>setAddModalVisible(false)}
        footer={false}
        destroyOnClose
        maskClosable={false}
      >
        <Form
          labelCol={{
            span: 6,
          }}
          initialValues={{
            type: '1',
          }}
          onFinish={(values) => handleAddPage(values)}
        >
          <Form.Item
            label={t('aticleTitle')}
            name="title"
            rules={[{required: true, message: '必填！', whitespace: true}]}
          >
            <Input placeholder="最多50字" maxLength={50}/>
          </Form.Item>
          <Form.Item wrapperCol={{
            offset: 6,
          }}>
            <Button type="primary" htmlType="submit">
              {t('add')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        className="edit-modal"
        title={t('editArticle')}
        visible={editModalVisible}
        onCancel={()=>{
          setEditModalVisible(false);
          form.resetFields();
        }}
        onOk={()=>{
          handleAddArticleModules(currentArticle.id, templateList);
          form.resetFields();
        }}
        destroyOnClose
        maskClosable={false}
        width={960}
        footer={null}
      >
        <Spin spinning={httpLoading}>
          <Row style={{margin: 16}}>
            <Col span={24} style={{padding: 10}}>
              <Form
                form={form}
                onFinish={
                  (values) => {
                    handleUpdateModule(currentModuleIdx, values);
                  }}
              >
                {currentModule.moduleId&&currentModule.moduleId!==0?
                modelForm.find((i)=>i.id==currentModule.moduleId).data.map((item, index)=>{
                  if (item.type==='text'||item.type==='title') {
                    return (
                      <Form.Item
                        {...layout}
                        label={item.label}
                        name={item.name}
                        key={index}
                      >
                        {item.type==='text'?<Input.TextArea row={3} style={{resize: 'none'}}/>:<Input />}
                      </Form.Item>
                    );
                  } else if (item.type==='image') {
                    return (
                      <Form.Item label={item.label} key={index} {...layout}>
                        <Form.Item>
                          <Space>
                            <Upload {...imageUploadProps}>
                              <Button icon={<UploadOutlined />} onClick={()=>{
                                setImagesBoxVisible(false);
                                setVideoBoxVisible(false);
                                setCurrentImage(item);
                              }}>{t('upload')}</Button>
                            </Upload>
                            {imagesBoxVisible?<Button onClick={()=>{
                              setImagesBoxVisible(false);
                              setVideoBoxVisible(false);
                            }}>{t('close')}</Button>:
                            <Button onClick={()=>{
                              setVideoBoxVisible(false);
                              setImagesBoxVisible(true);
                            }}>{t('chooseUploadedFile')}</Button>}
                          </Space>
                          <div>
                            {imagesBoxVisible?(
                              <div className="image-box">
                                <Card title={t('uploadedImage')} style={{margin: '4px 0'}}>
                                  {imageList.map((i, index)=>(
                                    <div key={index} className="image-wapper">
                                      <Tooltip title={t('choose')}>
                                        <img
                                          style={{maxWidth: 100, maxHeight: 100}}
                                          src={i.url}
                                          onClick={()=>{
                                            form.setFieldsValue({
                                              [item.name]: i.url,
                                            });
                                            setImagesBoxVisible(false);
                                          }}
                                        />
                                      </Tooltip>
                                    </div>
                                  ))}
                                </Card>
                                <Pagination
                                  size="small"
                                  showSizeChanger={false}
                                  style={{margin: '20px 10px 0 0', float: 'right'}}
                                  {...imageListPageConfig}
                                  onChange={(page)=>{
                                    handleGetAllImages(page, 8, setImageList);
                                  }}
                                />
                              </div>):null}
                          </div>
                        </Form.Item>
                        <Form.Item
                          name={item.name}
                          shouldUpdate
                        >
                          <Input disabled/>
                        </Form.Item>
                      </Form.Item>
                    );
                  } else if (item.type==='herf') {
                    return (
                      <Form.Item
                        {...layout}
                        label={item.label}
                        name={item.name}
                        key={index}
                      >
                        <Input.TextArea row={3} placeholder={t('herfInfo')} style={{resize: 'none'}}/>
                      </Form.Item>
                    );
                  } else if (item.type==='href') {
                    return (
                      <Form.Item
                        {...layout}
                        label={item.label}
                        name={item.name}
                        key={index}
                        rules={[{required: true, message: '必填'}]}
                      >
                        <Input placeholder={t('hrefInfo')} />
                      </Form.Item>
                    );
                  } else if (item.type==='video') {
                    return (
                      <Form.Item label={item.label} key={index} {...layout}>
                        <Form.Item>
                          <Space>
                            <Upload {...videoUploadProps}>
                              <Button icon={<UploadOutlined />} onClick={()=>{
                                setImagesBoxVisible(false);
                                setVideoBoxVisible(false);
                                setCurrentVideo(item);
                              }}>{t('upload')}</Button>
                            </Upload>
                            {videoBoxVisible?<Button onClick={()=>{
                              setImagesBoxVisible(false);
                              setVideoBoxVisible(false);
                            }}>{t('close')}</Button>:
                            <Button onClick={()=>{
                              setImagesBoxVisible(false);
                              setVideoBoxVisible(true);
                            }}>{t('chooseUploadedFile')}</Button>}
                          </Space>
                          <div>
                            {videoBoxVisible?(
                              <div className="image-box">
                                <Card title={t('uploadedVideo')} style={{margin: '4px 0'}}>
                                  {videoList.map((i, index)=>(
                                    <div key={index} className="image-wapper">
                                      <Tooltip title={t('choose')}>
                                        <img
                                          style={{maxWidth: 100, maxHeight: 100}}
                                          src={i.thumbnail}
                                          onClick={()=>{
                                            form.setFieldsValue({
                                              [item.name]: i.url,
                                            });
                                            setVideoBoxVisible(false);
                                          }}
                                        />
                                      </Tooltip>
                                    </div>
                                  ))}
                                </Card>
                                <Pagination
                                  size="small"
                                  style={{margin: '20px 10px 0 0', float: 'right'}}
                                  {...videoListPageConfig}
                                  onChange={(page)=>{
                                    handleGetAllVideos(page, 8, setVideoList);
                                  }}
                                />
                              </div>):null}
                          </div>
                        </Form.Item>
                        <Form.Item
                          name={item.name}
                          shouldUpdate
                        >
                          <Input disabled/>
                        </Form.Item>
                      </Form.Item>
                    );
                  }
                }):null}
                <Form.Item {...tailLayout}>
                  <Button type="primary" htmlType="submit">
                    {t('submit')}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Spin>
      </Modal>
      <Modal
        title={t('preview')}
        visible={previewModalVisible}
        onCancel={()=>setPreviewModalVisible(false)}
        destroyOnClose
        width={1400}
        footer={null}
      >
        <Row>
          <Col span={24} style={{height: '100%', overflowY: 'auto'}}>
            <div className="wrap">
              <div id="primary" className="content-area">
                <main id="main" className="site-main" role="main">
                  <div className="container">
                    <article id="post-52" className="post-52 page type-page status-publish hentry">
                      <div className="entry-content">
                        <div
                          dangerouslySetInnerHTML={{__html: htmlData}}></div>
                      </div>
                    </article>
                  </div>
                </main>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

Article.propTypes = {
  history: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Article));
