import React from 'react';
import ReactDOM from 'react-dom';
import {ConfigProvider} from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import './index.css';
import App from './App.jsx';
import {Provider} from 'react-redux';
import store from './store';
ReactDOM.render(
    <ConfigProvider locale={zhCN}>
      <Provider store={store}>
        <App />
      </Provider>
    </ConfigProvider>,
    document.getElementById('root'),
);
