export const LOGIN = '/api/user/userLogin';
export const ADDFILE = '/api/file/add';
export const UPLOAD = '/api/model/upload';
export const GETMODEL = '/api/model/getModelList';
export const GETCONTENT = '/api/model/getContent';
export const UPDATEMODEL = '/api/model/updateContent';
export const GETPAGELIST = '/api/navigation/list';
export const ADDNEWPAGE = '/api/navigation/add';
export const GETPAGEMODULES = '/api/pageModuleList/';
export const ADDPAGEMODULES = '/api/addPageModule/';
export const UPDATEPAGEMODULE = '/api/savePageModule/';
export const GETNAVIGATION = '/api/navigation/list';
export const ADDNAVIGATION = '/api/navigation/add';
export const RELATEARTICLE = '/api/navigation/relation/';
export const UPDATENAVIGATION = '/api/navigation/update';
export const DELETENAVIGATION = '/api/navigation/del/';
export const GETARTICLELIST = '/api/articlePageList/';
export const ADDARTICLE = '/api/addArticlePage';
export const DELETEARTICLE = '/api/delArticlePage/';
export const GETARTICLEMODULES = '/api/articlePageModuleList/';

export const GETARTICLEMODULELIST = '/api/articlePageList/';
export const ADDARTICLEMODULE = '/api/addArticlePageModule/';
export const DELETEARTICLEMODULE = '/api/delArticlePageModule/';
export const UPDATEARTICLEMODULE = '/api/updateArticlePageModule/';
export const PREVIEWPAGE = '/api/articlePageModuleList/preview/';
export const SAVEARTICLEMODULE = '/api/saveArticlePageModule/';
export const PUBLISHARTICLE = '/api/articlePageList/publish/';


export const GETMULTITEXTLIST = '/api/file/list';
export const ADDMULTITEXT = '/api/file/add';
export const UPDATEMULTITEXT = '/api/file/update/';
export const DELETEMULTITEXT = '/api/file/del/';

export const GETBANNERLIST = '/api/banner/list';
export const UPLOADBANNER = '/api/uploadBanner';

export const GETALLIMAGES = '/api/files?type=1';
export const GETALLVIDEOS = '/api/files?type=2';
export const ADDBANNER = '/api/banner/add';
export const UPDATEBANNER = '/api/banner/update';
export const DELETEBANNER = '/api/banner/del/';
export const UPDATEBANNERSTATUS = '/api/banner/updateStatus';
export const SORTBANNER = '/api/banner/sort';

export const GETFILES = '/api/files';
export const DELETEFILES ='/api/files/del/';
export const GETPICTURELIST='/api/files/multimedia';
export const LOGINOUT='/api/user/loginOut';
export const RESETPASSWORD='/api/user/modifyPassword';
export const GETEXTRAINFO='/api/icon/info';
export const SETEXTRAINFO='/api/icon/setting';

export const GETLOCATIONLIST='/api/location/list';
export const ADDLOCATION='/api/location/add';
export const UPDATELOCATION='/api/location/update';
export const DELETELOCATION='/api/location/delete/';
export const SORTLOCATION='/api/location/sort';
export const SETMAINLOCATION='/api/location/setMainLocation/';
