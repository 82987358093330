import React, {useState} from 'react';
import './style.css';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Card, Form, Input, Button, Spin, message} from 'antd';
import {useTranslation} from 'react-i18next';
import {storage} from '@/api/storage';
import {post} from '@/api/request';
import {LOGIN} from '@/api/index';
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 6,
    span: 16,
  },
};
const Login = ({history, login}) => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const [httpLoading, setHttpLoading] = useState(false);
  const handleSubmit = (values)=>{
    setHttpLoading(true);
    post(LOGIN, {username: values.username, password: values.password}, {
      'Content-Type': 'application/x-www-form-urlencoded',
    }).then((res)=>{
      if (values.remember) {
        storage.saveData('local', 'userInfo', res.data.data);
      } else {
        storage.saveData('session', 'userInfo', res.data.data);
      }
      login();
      history.push('home');
    }).catch((error)=>{
      message.error({
        content: error.toString(), key: 'netError', duration: 2,
      });
    }).finally(()=>setHttpLoading(false));
  };
  return (
    <div className={t('login') } >
      <Card title="Acclivis管理后台" style={{width: 400}}>
        <Spin spinning={httpLoading} tip="Loading...">
          <Form
            form={form}
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={(values)=>handleSubmit(values)}
          >
            <Form.Item
              label={t('userName')}
              name="username"
              rules={[
                {
                  required: true,
                  message: '请输入用户名。',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('password')}
              name="password"
              rules={[
                {
                  required: true,
                  message: '请输入密码。',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                登录
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

Login.propTypes = {
  history: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Login));
